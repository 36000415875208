
    .itemsCont{
      position: relative;
      top: 0;
      box-sizing:border-box;
      border-radius: 10px;
      padding:10px 5px;
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      overflow: auto;
      
      
      // &::after {
      //   content: "";
      //   flex: auto;
      // }

      label.disable{
        pointer-events: none;
      }
      
    
        label{
          width: auto;
          width: 33%;

          @media (max-width:1170px){
            width: 50%;
          }

          @media (max-width:960px){
            width: 100%;
          }

        }
      
        input[type=radio]{
          display: none;
        }
      
        .itemDiv{
          height: 110px;
          margin: 5px;
          cursor: pointer;
          border:3px solid transparent;
          position: relative;
          transition: .4s all ease;
          background-color: #f2f2f2;
          padding:15px;
          border-radius: 15px;
          border:4px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom:0;
          opacity: 1;
          position: relative;

          &:hover{
            background-color: white;
            -webkit-box-shadow: 0px 1px 7px 6px rgba(0,0,0,0.05); 
            box-shadow: 0px 1px 7px 6px rgba(0,0,0,0.05);
          }

          &.nothing{
            flex-direction: column;

            img{
              height: 70px;
              width: 70px;
              position: relative;
              top: 5px;
            }

            h5{
              color: #CBCBCB;
            }
          }

          .price{
            display: flex;
            align-items: center;
            position: absolute;
            bottom: -3px;
            left: 50%;
            transform: translateX(-50%);
            img{
              height: 17px;
              width: 17px;
              margin-right: 5px;
              position: relative;
              bottom: 2px;
            }
          }
          

          &.doNotDisplay{
            opacity: 0;
            // bottom:20px;
          }

      
          &.selected{
            border:3px solid #e2ff988a;
            border-radius: 25%;
            background-color:#f3f3f3b4 ;
          }
      
          img.itemImg{
            height: 70px;
            width:90px;
            object-fit: contain;
            position: relative;
            bottom: 10px;
          }

          img.owned{
            position: absolute;
            top:5px;
            right: 5px;
          }

          &.noPrice{
            img.itemImg{
              bottom:0;
            }
          }
        }

        input:checked+.itemDiv{
          border:4px solid #077CA0;
          background-color: #077ca03f;

          &.nothing{
            h5{
              color: #ffffff;
            }
          }
        }
      

        @media (max-width:660px){
          padding: 0 5px;
          height: 100%;

          label{
            width: 30%;
            // flex-grow: 1;
            min-width: 33%;
          }
          
        }
    }