@import '../../../Styles/shared.scss';

.profileSettings{
  height: 100%;
  width: 100%;
  box-sizing:border-box;
  padding:25px 30px !important;
  overflow: auto;

  h2{
    margin-bottom: 20px;
  }

  .row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    position: relative;



    .eye{
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 22px;
      height:20px;
      object-fit: contain;
      cursor: pointer;
    }

    label{
      p{
        margin-bottom: 10px;
        box-sizing:border-box;
        padding:0 0 0 5px;
        color: #4e4e4e;
        span{
          color: #cacaca;
          font-weight: 300;
          font-size: 13px;
          position: relative;
          bottom: 1px;
          margin-left: 5px;
        }
      }
      &.full{
        width: 100%;
      }
      &.three{
        width:calc(75% - 10px);
      }
      &.half{
        width:calc(50% - 10px);
      }
      &.one{
        width:calc(25% - 10px);
      }
    }

    .inputDiv{
      height: 50px;
      width: 100%;
      background-color: #f2f2f2;
      border:1px solid #cacaca;
      border-radius: 10px;
      box-sizing:border-box;
      padding:0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      p{
        font-size: 17px;
        color: #8d8d8d;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 110px);        
        white-space: nowrap;
        margin: 0;
        font-family: Arial, Helvetica, sans-serif;
      }

      .copyDiv{
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: relative;
        left: 8px;
        border:1px solid #077CA0;
        background-color: white;
        cursor: pointer;
        transition: .2s all ease-out;
        
        p{
          padding: 0;
          color: #077CA0;
          max-width: initial;
        }
        
        &:hover{
          background-color: #077CA0;
          p{
            color: white;
          }
        }
      }
    }

    input{
      height: 50px;
      width: 100%;
      background-color: #f2f2f2;
      border:1px solid #cacaca;
      border-radius: 10px;
      box-sizing:border-box;
      padding:0 15px;
      color: #858585;
      font-size: 17px;

      &::placeholder{
        opacity: .3;
      }

      &.editable{
        background-color: #ffffff;
        color: #333333;
      }


      &.passwordInput{
        font-size: 30px;
      }
    }
  }

  .changePassword{
    // width: 100%;
    text-align: left;
    color: #077CA0;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    top: 5px;

    &:hover{
      text-decoration: underline;
    }
  }

  .change{
    position: relative;
    top:-10px;
    opacity: 0;
    transition: .2s all ease-in-out;
    margin-bottom: 30px;

    &.showChange{
      top:0;
      opacity: 1;
    }

    .loadingOverlay{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.563);
      box-sizing:border-box;
      padding:0 0 60px 0;
      pointer-events: none;
      opacity: 1;

      &.hide{
        opacity: 0;
      }

      h6{
        color: $main-teal;
        position: relative;
        bottom: 35px;
      }

      &.confirmed{
        background-color: white;

        img.tick{
          height: 70px;
          position: relative;
          bottom: 90px;
        }
        h6{
          color: $main-teal;
          position: relative;
          bottom: 80px;
        }
      }

    }
  }

  .current{
    position: relative;
    top:-10px;
    opacity: 0;
    transition: .2s all ease-in-out;

    &.showCurrent{
      top:0;
      opacity: 1;
    }
  }

  .buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;

    .cancelBtn{
      color: #077CA0;
      font-weight: 500;
      cursor: pointer;
      padding: 0 15px;
      margin: 0 10px;

      &:hover{
        text-decoration: underline;
      }
    }

    .confirmBtn{
      @include solidButton ( #077CA0, white, 15px, 40px, auto);
      padding: 0 20px;
    }
  }

  p.error{
    width: 100%;
    text-align: center;
    color: #fb3333;
    position: relative;
    bottom: 35px;
    font-size: 14px;
  }
}