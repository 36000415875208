@import '../../../Styles/shared.scss';

.container{
  box-sizing:border-box;
  width: 100%;
  padding:40px 50px;

  &.voted{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noEntries{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img{
      height: 120px;
      width: auto;
      margin-bottom: 10px;
    }
  }

  .topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text{
      display: flex;
      width: calc(100% - 270px);
      align-items: center;
      position: relative;
      left: 15px;

      h5{
        line-height: 1.2;
      }

      img{
        margin-right: 20px;
      }
    }

    .progressDiv{
      width: 250px;
      position: relative;

      h6{
        color:#077CA0;
        position: absolute;
        top:-40px;
      }
    }
  }

  .entries{
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;

  }

  @media (max-width:1024px){
    height: 100%;
    overflow: scroll;
    
    .entries{
      flex-direction: column;
      height: fit-content;
    }
  }

  &.mobile{
    padding: 0px;
    position: relative;
    .topBar{
      padding: 20px;
      flex-direction: column;

      .text{
        width: 100%;

        h5{
          font-size: 15px;
        }
      }

      .progressDiv{
        width: 95%;
        margin: 15px 0 0px ;

        h6{
          position: relative;
          top: initial;
        }
      }
    }

    .overlay{
      height: 300px;
      width: 100%;
      background-color: transparent;
      position: absolute;
      z-index: 20;
    }

    .entries{
      position: relative;
      bottom:15px;
      display: block;
      overflow: scroll;
      padding-left: 5vw;
      // pointer-events: none;

    }

    .entriesInner{
      padding-top: 15px;
      // width: 275%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: scroll;
    }
  }

  .finishWriting{
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-align: center;
    width: fit-content;
    padding:0 25px;
    transition: .2s background-color ease;
    margin: 15px 5px 45px auto;
    background: #FFC226;
    background: linear-gradient(139deg, #FFC226 0%, #DC7C0C 100%);
  }
}