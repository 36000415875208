@import '../../../Styles/shared.scss';

.container{
  height: 80vh;
  width: 80vw;
  background-color: white;
  border-radius: 10px;
  box-sizing:border-box;
  padding:40px 70px;
  opacity: 0;
  position: relative;

  &.fullSize{
    transition: .7s cubic-bezier(0.49, 0.87, 0.4, 2.3) opacity;
    opacity: 1;
  }

  &.mobile{
    padding:20px;
    top: 20px;
    height: calc(var(--viewportHeight) - 80px) !important;
    width: calc(100vw - 30px) !important;

    img.close{
      height: 20px;
      top: 25px;
    }

    .entryText{
      margin-top: 15px;
      margin-bottom: 10px;
      height: calc(100% - 115px);
    }
  }


  img.close{
    position: absolute;
    top:20px;
    right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .entryText{
    margin-top: 30px;
    margin-bottom: 20px;
    height: calc(100% - 130px);
    overflow: scroll;
    width: 100%;
    box-sizing:border-box;
    padding:0 15px 0 0;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #c4c4c4;
    }

    *{
      font-weight: 300;
    }
  }

  .buttons{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;


    .prevBtn{
      @include solidButton( #CDDBDF, #074B60, 16px, 50px, 190px);
      padding-left: 25px;
      background: url('../../../Assets/Icons/arrow_blue_left.svg') no-repeat left 15px center, #CDDBDF;
      margin-right: 15px;

      &:hover{
        background: url('../../../Assets/Icons/arrow_white_left.svg') no-repeat left 15px center, #074B60;
        color:white;
      }
    }

    .nextBtn{
      @include solidButton( #074B60, white, 16px, 50px, 160px);
      padding-right: 25px;
      background: url('../../../Assets/Icons/arrow_white_right.svg') no-repeat right 15px center, #074B60;
      &:hover{
        background: url('../../../Assets/Icons/arrow_blue_right.svg') no-repeat right 15px center, white;
      }
    }

    .disabled{
      opacity: .2;
      pointer-events: none;
    }

  }
}