@import '../../Styles/shared.scss';

.container{
  padding: 0 !important;
  height: 100%;
  overflow: hidden !important;
  display: flex;

  &.synod{
    height: var(--viewportMinus);

    .idxSquare{
      height: 35px;
      width: 35px;
      line-height: 35px;
      text-align: center;
      border-radius: 5px;
      background-color: $E_blue_light2;
      color: $E_blue_dark;
      font-size: 20px;
      box-sizing:border-box;
      padding:1px 0 0 0;
      margin-right: 20px;
    }

    .viewAllBtn{
      background-color: transparent !important;
      position: relative;
      bottom: 2px;
      left: -10px;

      p{
        font-weight: 300;
      }
    }
  }

  .topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    // border-bottom:1px solid #cacaca;
    padding: 0 10px;

    .left{
      display: flex;
      align-items: center;

      .viewAllBtn{
        height: 40px;
        width: 145px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 20px;
        border-radius: 10px;
        transition: .25s all ease-out;
        background-color: $E_blue_light;


        &:hover{
          background-color: $E_blue_light2;
        }
        p{
          font-size: 13px;
          color: $E_blue;
          margin: 0;
          position: relative;
          bottom: -2px;
        }
      }
    }
  }


  .main{
    height: 100%;
    // display: flex;
    width: 100%;
    transition: .8s cubic-bezier(0.67, -0.19, 0.57, 1.3) all, .3s background-color ease;
    position: relative;

    &.privateEnabled{
      background-color: #FCF2EA;
    }
  
    .privateOverlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #FCF2EA;
      z-index: 5;
      pointer-events: none;
      transition: .3s all ease-out;
      opacity: 0;

      &.enabled{
        display: none;
        opacity: 1;
        opacity: 0;
      }
    }

    .storyStartContainer{
      overflow: auto !important;
      width: 100%;
      font-family: Arial, Helvetica, sans-serif;
      resize: none;
      box-sizing:border-box;
      padding:20px 15px;
      font-size: 17px;
      height: calc(100% - 85px);
    }

    &.sml{
      width: calc(100% - 500px);
    }
  }

  .sidebar{
    height: 100%;
    width: 150px;
    border-left: 1px solid #cacaca;
    // transition: 0.45s ease-in all;
    transition: .8s cubic-bezier(0.67, -0.19, 0.57, 1.3) all;
    
    &.open{
      width: 500px;
      transition: 0.9s cubic-bezier(0.4, -0.4, 0.4, 1.4) all;
    }
  }

  @media (max-width:1110px){
    
    .main{
      // height: calc(100% - 85px);

      &.sml{
        width: calc(100% - 350px);

      }
      // border:3px solid chartreuse;
    }
    
  }

}
@media (max-width:865px){

  .container{
  
    .main{
      overflow: hidden;
      // height: calc(100vh - 170px);
    }
  }
}

@media (max-width:650px){

  .container{
    display: none; 
  
    .main{
      overflow: hidden;
      // height: calc(100vh - 155px);
    }
  }
}