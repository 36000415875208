@import '../../../../../Styles/shared.scss';

    
.container{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-sizing:border-box;
  padding:5px 15px;
  position: relative;
  transition: .6s ease-out opacity, .3s ease-in-out top;
  opacity: 0;
  top: 15px;

  &.display{
    opacity: 1;
    top: 0;
  }

  .pills{
    height: fit-content;
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: all .3s ease-in-out;
    position: relative;
    border-radius: 8px;
    border: 1px solid #cacaca;
    box-sizing:border-box;
    padding:5px 15px;

    input[type='radio']{display:none};

    label>input+.row{
      width: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: .3s all ease-out;

      p{
        text-align: center;
        font-size: 14px;
        color: black;
        margin:11px 8px;
      }
      .number{
        height:22px;
        width:22px;
        line-height: 23px;
        border-radius: 50%;
        font-size: 14px;
        text-align: center;
        color: white;
        margin-right: 2px;
        box-sizing:border-box;
        padding:0 0 0 1px;
      } 
    }

    label{
      min-width: 140px;
      width: 49%;

      input+.row{
        width: 100%;
        height: 23px;
        border-radius: 13px;
        background-color: #e9e9e9;
        padding: 0 !important;
        margin:3px 0;
        border:1px solid transparent;

        p{
          font-size: 15px;
          font-weight: 300;
          color: #4e4e4e;
          margin:3px 0;
          transition: none;
        }
        .number{
          color: #4e4e4e;
          margin-left: 5px;
        }
        &:hover{
          p, 
          .number{
            color: white
          }
        }
      }

      input:checked+.row{
        color: white;
        p, 
        .number{
          color: white
        }
      }

      &:nth-of-type(1) { //PASSIVE VOICE
        
        input+.row {
          &:hover {
            border-color: #f26283;
            background-color: #f262844a;
          }
        }

        input:checked+.row{
          background-color: #f26283;
        }
      }


      &:nth-of-type(2) { //INITIAL PRONOUNS

        input+.row {
          &:hover {
            border-color: #ffa826;
            background-color: #ffa8264a;
          }
        }

        input:checked+.row{
          background-color: #ffa826;
        }
      }

      &:nth-of-type(3) { //GRAMMAR

        input+.row {
          &:hover {
            border-color: #8e00f0;
            background-color: #8e00f04a;
          }
        }

        input:checked+.row{
          background-color: #8e00f0;
        }
      }


      &:nth-of-type(4) { //OVERUSE

        input+.row {
          &:hover {
            border-color: #077ca0;
            background-color: #077ca04a;
          }
        }

        input:checked+.row{
          background-color: #077ca0;
        }
      }

      &:nth-of-type(5) { //CONSITENCY

        input+.row {
          &:hover {
            border-color: #FF8258;
            background-color: #FF82584a;
          }
        }

        input:checked+.row{
          background-color: #FF8258;
        }
      }

    }
  }


  div.entryText {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #858585;
    font-size: 14px;
    font-weight: 300;;
    box-sizing:border-box;
    padding:0px 10px 0 0;
    text-align: justify;
    align-items: stretch;
    resize: none;
    width: calc(100% + 10px);
    height: calc(100% - 120px);
    flex:1;
    background-color: white;
    margin-top: 25px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: .3s ease-in-out all;

    &::-webkit-scrollbar {
      width: 9px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #cacaca;
      border:1px solid white;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &::-webkit-scrollbar-track:start {
      background-color: transparent;
      margin-top: 30px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 10px;
    }

    &::-webkit-scrollbar-track:end {
      background-color: transparent;
      margin-bottom: 30px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 10px;
    }
  }

  @media (max-width:1024px){
    label{
      width: 100% !important;
    }
  }
}
