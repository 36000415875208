@import '../../../../Styles/shared.scss';


.closedButtons{
  width: 133px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 10px 8px;
  transition: .5s ease-in all;
  opacity: 0;
  top:50px;
  left:-5px;
  position: absolute;
  pointer-events: none;
  
  
  &.display{
    opacity: 1;
    pointer-events: all !important;
}

  input[type=radio]{
    display: none;
  }

  .sidebarOption{
    height: 100px;
    width: 100px;
    border-radius: 10px;
    background-color: #E9E9E9;
    margin-bottom: 10px;
    cursor: pointer;
    transition: .3s ease-out all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img{
        position: absolute;
        transition: .3s ease-out all;
      }

      img.hover,
      img.selected{
        opacity: 0;
      }


      .boomerCircle{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border:1px solid transparent;

        img{
          height: 34px;
          width: auto;
          position: relative;
          bottom: 2px;
        }

        &.one{
          left:5px;
          z-index: 10;
          border-color:#E9E9E9;
        }
        &.two{
          right:5px;
        }
      }
    }

    &:hover{
      background-color: #D2D2D2;

      img.init{
        opacity: 0;
      }
      img.hover{
        opacity: 1;
      }
    }

    h5{
      width: 80px;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      height: 35px;
      display: flex;
      align-items: center;
      // border:1px solid chartreuse;
    }
  }

  input:checked+.sidebarOption{
    background-color: #074B60;

    .icon{
      img.init{
        opacity: 0;
      }
      img.selected{
        opacity: 1;
      }

      .boomerCircle.one{
        border-color:#074B60;
      }
    }

    h5{
      color: white;
    }
  }
}