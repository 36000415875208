@import '../../../Styles/shared.scss';

.container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // justify-content: center;
  width: 100px;
  height: 40px;
  overflow: visible;
  transition: .6s ease-in-out all;
  position: relative;
  left: 10px;
  
  &.open{
    width: 130px;
  }

  h5{
    margin-left: 5px;
    font-size: 12px;
    white-space: nowrap;
    transition: .6s ease-in-out all;
    
    &.small{
      font-size: 10px;
    }
    &.large{
      font-size: 14px !important;
    }

    &.finished{
      color: #2b4e7b98 !important;
    }

    &.twenty{
      color: #077CA0 !important;
    }

    &.ten{
      color: #2B4E7B !important;
    }

    &.five{
      color: #FF8258 !important;
    }

    &.two{
      color: #fb3333 !important;
    }
  }
}