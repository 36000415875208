.loaderWrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.noNav{
    width: calc(100% - 85px);
    position: relative;
    left: 85px;
  }

  img.loader{
    height: 150px;
  }
}
