@import '../../../Styles/shared.scss';

.container{
  height: 100%;
  display: flex;
  align-items: center;

  .backArrow{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: url('../../../Assets/Icons/arrow_square.png');
    cursor: pointer;
    
    &:hover{
      background: url('../../../Assets/Icons/arrow_square_hover.png');
    }
  }

  .content{

    margin-left: 15px;
    
    .text{
      display: flex;
      padding:0 10px;
    }

    a,
    p{
      font-size: 12px;
      color:#4e4e4e;
    }

    a:hover{
      text-decoration: underline;
    }

    p:first-of-type{
      margin: 0 5px;
    }
  }
}