@import '../../../../Styles/shared.scss';

.sectionStatusPill{
  display: flex;
  width: fit-content;
  align-items: center;

  
  img{
    margin-right: 10px;
    height: 30px;
  }
  
  h4{
    color: white;
    padding: 6px 20px 5px 20px;
    border-radius: 15px;


    &.tall{
      padding: 12px 20px !important;  
      transition: .15s all ease;
      position: relative;
      bottom: 0;

      &:hover{
        bottom:3px;
        -webkit-box-shadow: 0px 4px 15px -2px rgba(6, 0, 0, 0.3);
        -moz-box-shadow:    0px 4px 15px -2px rgba(6, 0, 0, 0.3);
        box-shadow:         0px 4px 15px -2px rgba(6, 0, 0, 0.3);}
    }
    
      &.orange{
        background: rgb(255,168,38);
        background: linear-gradient(139deg, rgba(255,168,38,1) 0%, rgba(255,116,38,1) 100%);
      }

      &.pink{
        background: rgb(242,98,131);
        background: linear-gradient(139deg, rgba(242,98,131,1) 0%, rgba(254,135,95,1) 100%);
      }

      &.purple{
        background: rgb(6,95,229);
        background: linear-gradient(180deg, rgba(6,95,229,1) 0%, rgba(142,0,240,1) 66%);
      }

      &.grey{
        background-color:#B2BCCB ;
      }
  }
}