@import '../../../Styles/shared.scss';

.container{
  width: 100%;
  // height: fit-content;
  overflow: hidden;
  max-height: 351px;
  box-sizing:border-box;
  padding:10px ;
  transition: 1.4s all ease;

  &.open{
    max-height: 5000px;
  }

  .taskRow{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    max-width:1100px;
    // border: 1px solid #ebebeb;
    box-sizing:border-box;
    padding:0 30px;
    position: relative;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 1px 10px 2px rgba(0, 99, 192, 0.2);
    -moz-box-shadow:    0px 1px 10px 2px rgba(0, 99, 192, 0.2);
    box-shadow:         0px 1px 10px 2px rgba(0, 99, 192, 0.2);
    bottom: 0;
    transition: .1s all ease;
    
    &:hover{
      bottom: 3px;
      -webkit-box-shadow: 0px 4px 15px 2px rgba(0, 99, 192, 0.2);
      -moz-box-shadow:    0px 4px 15px 2px rgba(0, 99, 192, 0.2);
      box-shadow:         0px 4px 15px 2px rgba(0, 99, 192, 0.2);
    }


    h5.groupName{
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #858585;
      position: absolute;
      left: 30px;
      top: 0px;
      width: fit-content;
      z-index: 20;
      font-weight: 500;
    }


    .circleCont{
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      border-top-left-radius: 8px;
  
      .circle{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        position: relative;
        top: -10px;
        left:-12px;
  
        &.one{
          background-color: #97D318;
        }
  
        &.two{
          background-color: #F26283;
        }
  
        &.three{
          background-color: #FF8258;
        }
  
        &.four{
          background-color: #077CA0;
        }
  
        &.grey{
          background-color: #cacaca;
        }
      }
    }



    .left{
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: calc(50% - 70px);;
      min-width: calc(50% - 70px);;
      
      .text{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        box-sizing:border-box;
        padding:5px 0;
        max-width: 350px;
        p{
          font-size: 15px;
          font-weight: 500;
          color: #4e4e4e;
          background: url('../../../Assets/Icons/book_icon_yellow.svg') no-repeat left center;
          padding-left: 23px;
        }
      }

      .chevron{
        height: 40px;
        width: 30px;
        margin:0 15px;
        background: url('../../../Assets/Icons/chevron_grey.svg') no-repeat center;
      }
    }
    
    .middle{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: calc(50% - 70px);

      .book{
        height: 50px;
        width: 50px;
        background: url('../../../Assets/Icons/book_icon_greyblue.png') no-repeat center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        h3{
          position: relative;
          left: 2px;
          color: #074B60;
          font-size: 20px;
        }
      }

      h3{
        color: #4e4e4e;
        &.name{
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }


    .right{
      width: 450px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .deadline{
      display: flex;

      h6{
        margin-left: 10px;
        color:#077CA0
      }
    }

    .pill{
      transform: scale(1.2);
      cursor: pointer;
      width: 210px;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
    }
  }

  @media (max-width:1100px){


    // .left{
    //   width: 60% !important;
    // }
    .deadline{
      width: 120px;
    }
    .right{
      width: fit-content !important;

      .pill{
        width: fit-content !important;
        padding-left: 20px;
      }

    }
    
  
    // .taskRow{
    //   height: 250px;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: space-evenly;

    //   *{
    //     text-align: center;
    //   }

    //   .left{
    //     width: 100%;
    //     flex-direction: column;

    //     .text{
    //       margin-top: 5px;
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //     }
    //   }
    //   .right{
    //     flex-direction: column-reverse;
    //     // width: fit-content;
    //     justify-content: center;
    //     align-items: center;
    // //     // border:1px solid chartreuse;
    //   }
    //   .pill{
    //     justify-content: center;
    //     width: 230px;
    //     margin-bottom: 10px;
    //   }
    // }
  }

  @media (max-width:650px){
    border: none;
    border-radius: 0px;
    max-height: 335px !important;

    .taskRow{
      height: 100px;
      border-radius: 10px;
      margin-bottom: 10px;

      .deadline{
        display: none;
      }

      .pill{
        transform: scale(.9);
        width: 100px !important;
        position: relative;
        left: 30px;
      }

      .left{
        width: calc(100% - 100px) !important;
        position: relative;
        max-width: calc(100% - 100px) !important;;
        min-width: calc(100% - 100px) !important;;

        .text{
          width: 100%;

          p{
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-width: 100%;
            overflow: hidden;
          }
        }

        h5{
          font-size: 18px;
          span{
            display: none !important;
          }
        }

        .iconDiv{
          height: 20px;
          width: 20px;
          position: absolute;
          top: -1px;

          background-color: #f2f2f2;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
    
          img.icon{
            height: 13px;
            width: auto;
          }
        }
      }

      .middle, .chevron{
        display: none;
      }

      .pill{
        width:150px;
      }
    }
  }
  
}