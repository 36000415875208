.viewRadios{

  &.responsive{
    display: none;
  }

  input{
    display: none;
  }
  display: flex;
  align-items: center;

  .viewRadio{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:0 20px 0 15px;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    opacity: .5;
    cursor: pointer;
    transition: .3s all ease;
    background: #D2D2D2;
    margin: 0 4px;

    &:hover{
      opacity: 1;
    }

    p{
      font-size: 13px;
      position: relative;
      top:1px;
      font-weight: 300;
    }

    .listIcon,
    .gridIcon{
      height: 20px;
      width: 20px;
    }

    .listIcon{
      background: url('../../../Assets/Icons/view-list_black.svg') no-repeat center;
    }
    .gridIcon{
      background: url('../../../Assets/Icons/view-grid_black.svg') no-repeat center;
    }
  }

  input:checked+.viewRadio{
    background: #074B60;
    opacity: 1;
    p{
      color: white;
    }


    .listIcon{
      background: url('../../../Assets/Icons/view-list_white.svg') no-repeat center;
    }
    .gridIcon{
      background: url('../../../Assets/Icons/view-grid_white.svg') no-repeat center;
    }
  }

  @media (max-width:650px){
    
    &.responsive{
      display: flex;
      width: fit-content;
      position: absolute;
      z-index: 10;
      top:5px;
      right: 20px;

      .viewRadio{
        padding: 0;

        .listIcon,
        .gridIcon{
          height: 40px;
          width: 40px;
        }

        width: 40px;
        p{
          display: none !important;
        }
      }
    }
  }
}