@import '../../../Styles/shared.scss';

.container,
.loaderDiv{
  height: 600px;
  width: 900px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border:1px solid red;
  display: none;
  position: relative;

  .spinner{
    position: absolute;
    height: 100px;
    width: 100px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }

  .closeIcon{
    position: absolute;
    top:50px;
    right: 50px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 100;
  }

  &.display{
    display: flex;
  }
}