@import '../../../Styles/shared.scss';

label{
  input[type=radio]{
    display: none;
  }

  .radioDiv{
    height: 65px;
    width: 220px;
    transition: .3s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #cacaca;
    position: relative;
    margin: 0 2px;
    overflow: hidden;
    box-sizing: border-box;


    &:after{
      content:"";
      height: 0;
      width: 0;
      background: #88E2FE;
      border-radius: 15px;
      bottom: -5px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: .3s all ease-in-out;
    }

    &:hover{
      h5{
        color: #077B9F;
      }
    }


    &.blue{
      background-color: #CDDBDF;
      border-color: transparent;

      &:hover{
        background-color: #DBEBEF;
      }
    }

    .numberDot{
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FB3333;
      border-radius: 50%;
      position: absolute;
      top:3px;
      right: 3px;


      p{
        margin: 0;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .iconDiv{
      width: 60px;
      height: 100%;
      background: url('../../../Assets/Icons/book_icon_blue.png') no-repeat center;
      background-size: 25px;
    }

    h5{
      text-align: left;
      line-height: 25px;
      width:calc(100% - 110px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  input:checked+.radioDiv{
    background: #077B9F;
    border-width: 0px;
    width: 300px;
    pointer-events: none;

    &:after{
      height: 10px;
      width: 200px;
    }

    .iconDiv{
      width: 60px;
      height: 100%;
      background: url('../../../Assets/Icons/book_icon_white.png') no-repeat center;
      background-size: 50px;
    }


    h5{
      color: white;
    }
  }

  &.modal{
    width: 100%;
    margin-bottom: 5px;
    height: 100px;
    .radioDiv{
      width: 100% !important;
      height: 100%;
    }
  }

  @media (max-width:650px){

    .radioDiv{
      &:hover{
        background-color: #077B9F !important;
      }
    }
  }
}