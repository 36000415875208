@import '../../../Styles/shared.scss';

.container {
  margin: 20px 10px;
  width: 100%;
  height: 100%;
  font-weight: 300;
}

ul {
  list-style: circle !important;
  padding-left: 25px;
}
