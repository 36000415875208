@import '../../../Styles/shared.scss';

.container{
  height: var(--viewportMinus);
  min-height: 600px;
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  justify-content: center;

  &::before{
    content: "";
    position: absolute;
    top: -70px;
    right: -120px;
    background: url('../../../Assets/Backgrounds/bg_flare.png') no-repeat;
    height: 450px;
    width: 400px;
  }
  &::after{
    content: "";
    position: absolute;
    bottom: -70px;
    left: -50px;
    background: url('../../../Assets/Backgrounds/bg_flare.png') no-repeat;
    height: 450px;
    width: 400px;
    transform: rotate(180deg);
    z-index: 1;
  }

  .tableDiv{
    width: 80vw;
    max-width: 1050px;
    height: calc(100vh - 350px);
    height: fit-content;
    position: relative;
    z-index: 10;
    margin-top: 40px;
  }
}