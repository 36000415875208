@import '../../../Styles/shared.scss';

.container{
  min-height: 100px;
  max-height: 100px;
  width: calc(100% - 20px);
  // overflow: hidden;
  box-sizing:border-box;
  transition: .45s all ease-in-out;
  transition: .3s ease-in all, .3s cubic-bezier(0.2,-0.1, 0.2, 2.2)bottom;
  margin:5px 5px 5px 0;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  -moz-box-shadow:    0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  box-shadow:         0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  opacity: 0;
  margin-bottom: 15px;
  position: relative;

  &.unconfirmed{
    pointer-events: none;
    cursor: default;
    -webkit-box-shadow: 0px 1px 15px -2px rgba(160, 160, 160, 0.4);
    -moz-box-shadow:    0px 1px 15px -2px rgba(160, 160, 160, 0.4);
    box-shadow:         0px 1px 15px -2px rgba(160, 160, 160, 0.4);
  }

  *{
    font-family: 'Sofia';
    z-index: 10;
    position: relative;
    pointer-events: none;
  }

  .clickHandler{
    position: absolute;
    top: 0;
    left:0;
    height: 100px;
    width: 100%;
    pointer-events: all !important;
    z-index: 1 !important;
  }

  .circleCont{
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-top-left-radius: 8px;

    .circle{
      height: 30px;
      width: 30px;
      border-radius: 50%;
      position: relative;
      top: -10px;
      left:-12px;

      &.one{
        background-color: #97D318;
      }

      &.two{
        background-color: #F26283;
      }

      &.three{
        background-color: #FF8258;
      }

      &.four{
        background-color: #077CA0;
      }

      &.grey{
        background-color: #cacaca;
      }
    }
  }


  &.display{
    bottom:0;
    opacity: 1;
  }

  .inner{
    overflow: hidden;
    min-height: 100px;
    max-height: 100px;
    transition: .3s ease-in all, .3s cubic-bezier(0.2,-0.1, 0.2, 2.2)bottom;
  }
  &.open{
    // height: fit-content;
    max-height: 600px;
    opacity: 1;

    .inner{
      max-height: 600px;

    }
  }

  


  .actionCount{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #FB3333;
    margin-left: 5px;
    position: absolute;
    top:-5px;
    right: -5px;
    transition: .2s all ease-out;
    opacity: 1;
    pointer-events: none;

    &.hidden{
      opacity: 0;
    }

    p{
      margin: 0;
      color: white;
      line-height: 24px;
      font-size: 14px;
      position: relative;
    }
  }
  .main{
    display: flex;
    height: 100px;
    width: 100%;
    padding:6px 8px;

  } 

  .content{
    width: 100%;
    // border:1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;
    padding:12px 0px 5px 7px;
    overflow: hidden;

    .topRow{
      display: flex;
      justify-content: space-between;

      .dots{
        min-height: 30px;
        max-height: 30px;
        min-width: 30px;
        max-width: 30px;
        background: url('../../../Assets/Icons/threeDots.png') no-repeat center;
        transition: .15s ease-in background-color;
        position: relative;
        cursor: pointer;
        transform:rotate(90deg);
        border-radius: 8px;

        &:hover{
          background-color: #E9E9E9;
        }
      }



      h5{
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        // letter-spacing:1.02px;
        color: #333333;
        width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    h5.pending{
      font-weight: 300;
      color: #cacaca;
      font-size: 12px;
      width: 100%;
      text-align: right;
      position: relative;
      top: 10px;
      right: 5px;
    }


    .people{
      height: 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      
      .teachers,
      .students{
        width: 43%;
        display: flex;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .circleImg{
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          height: 30px;
          width: 30px;
          border:2px solid white;
          border-radius: 50%;
          position: relative;
          img{
            height: 28px;
            width: 28px;
            border-radius: 50%;
            border:2px solid #f2f2f2;
          }
        }
      }

      .teachers{
        .circleImg{
          &:nth-last-of-type(2){
            left: -12px;
          }
          &:nth-last-of-type(3){
            left: -25px;
          }

          .placeholder{
            height: 28px;
            width: 28px;
            border-radius: 50%;
            background-size: contain !important;
            
            &.one{
              background: url('../../../Assets/defaultAvatars/defaultPic1.svg') no-repeat center;
            }
            
            &.two{
              background: url('../../../Assets/defaultAvatars/defaultPic2.svg') no-repeat center;
            }
            
            &.three{
              background: url('../../../Assets/defaultAvatars/defaultPic3.svg') no-repeat center;
            }
            
            &.four{
              background: url('../../../Assets/defaultAvatars/defaultPic1.svg') no-repeat center;
            }
          }
        }
      }

      .students{
        flex-direction: row;
        justify-content: flex-end;
        .circles{
          display: flex;
        }
        .circleImg{
          -webkit-box-shadow: 0px 4px 10px -2px rgba(0, 99, 192, 0.3);
          -moz-box-shadow:0px 4px 10px -2px rgba(0, 99, 192, 0.3);
          box-shadow: 0px 4px 10px -2px rgba(0, 99, 192, 0.3);
          background-color: white !important;

          &:nth-last-of-type(2){
            right: -10px;
            z-index: 5;
          }
          
          &:nth-last-of-type(3){
            right: -18px;
            z-index: 10;
          }

          img{
            height: 50px;
            width: auto;
            min-width: 40px;
            position: relative;
            top: 7px;

            &.placeholder{
              height: 35px;
              width: 25px !important;
              min-width: initial !important;
            }
          }
        }
        .dot{
          max-height: 30px;
          max-width: 30px;
          min-height: 30px;
          min-width: 30px;
          border-radius: 50%;
          margin-left: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #077CA0;
          p{
            color: white;
            font-size: 16px;
          }
        }

      }

    
      .chevron{
        min-height: 30px;
        max-height: 30px;
        min-width: 30px;
        max-width: 30px;
        background: url('../../../Assets/Icons/arrow_square_chevron.png') no-repeat center;
        transition: .3s ease-in transform;
        position: relative;
        top: 10px;
        cursor: pointer;
        pointer-events: all !important;
  
        &.open{
          background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
          transform: rotate(180deg);
        }
        
        &:hover{
          background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
        }
      }
    }
  }

  .activitiesDiv{
    height: fit-content;
    max-height: 455px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing:border-box;
    padding:0 8px 8px 8px;
    pointer-events: all !important;

    .placeholder{
      height: 95px;
      min-height: 95px;
      max-height: 130px;
      border-radius: 8px;
      flex-grow: 1;
      max-width: 100%;
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border:1px solid #e9e9e9;
      margin-top: 8px;

      h5{
        font-weight: 100;
        font-size: 14px;
        color: #858585;
        letter-spacing: 1px;
      }
    }
  }

  @media (max-width:1250px){
    width: calc(100% - 15px);
    margin-top: 0 auto;;
  }
}





  
// .iconDiv{
//   width: 90px;
//   height: 100%;
//   border-radius: 8px;
//   margin-right: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &.one{
//     background-color: #FFD0C0;
//   }

//   &.two{
//     background-color: #E1FFA3;
//   }

//   &.three{
//     background-color: #FFEED4;
//   }

//   &.four{
//     background-color: #0EA0D3;
//   }

//   &.five{
//     background-color: #FFD0C0;
//   }

//   img{
//     height: 40px;
//     width: auto;
//   }
// }