@import '../../../Styles/shared.scss';

.noClassesInnerWrapper{
  height:100%;
  width:100%;
  background-color: $E_blue_light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing:border-box;
  padding:0 30px;

  img{
    margin-bottom: 10px;
  }

  h5{
    font-size: 20px;
    line-height: 30px;
    color: $E_blue;
  }


.newGroupCont{
  position: relative;
  margin-top: 5px;

  .newGroupBtn{
    height: 40px;
    width: 240px;
    border-radius: 10px;
    border:2px dashed  #608E9D;
    stroke-width: 5;
    stroke-dashoffset: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .1s background-color ease-out;
    z-index: 30;
    position: relative;
    margin:10px 0 !important;

    &:hover{
      background-color: $E_blue_light2;
    }

    .plus{
      height: 25px;
      width: 25px;
      background: url('../../../Assets/Icons/plus_blue_light.svg') no-repeat center;
      margin: 0 
    }

    p{
      color: #608E9D;
      font-size: 15px;
      margin:0;
      font-weight: 500;
      position: relative;
      bottom: -1px;
      margin-left: 10px;
      // left: 5px;
    }

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }
  }
}
    
.showModal{
  bottom: 55px !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

}