@import '../../../Styles/shared.scss';

.container {

  max-width: 1000px;
  min-width: 700px;
  width: 90vw;
  height: 700px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 15px;
  box-sizing:border-box;
  padding:30px 0;

  .inner{
    width: 90%;
    height: 100%;
    margin: 0 auto;

    .listDiv{
      position: relative;
      z-index: 10;
      margin-top: 10px;
      width: 100%;
      height: calc(100% - 110px);
    }
  }

  .slideHandle{
    display: none;
  }

  .activityBlock{
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    border-radius: 10px;
    max-width: 315px;
    min-width: 315px;
    margin: 10px 5px 5px 5px;
    position: relative;
    border-radius: 10px;
    border: 1px solid #cacaca;
    box-sizing:border-box;
    padding:5px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .iconDiv{
      width: 60px;
      height: 100%;
      background: url('../../../Assets/Icons/book_icon_blue.png') no-repeat center;
      background-size: 25px;
    }

    .sections{
      position: absolute;
      top: 5px;
      right: 10px;
      color: #077CA0;
      font-size: 11px;
      font-style: italic;
    }
  
  
    &.blue{
      background-color: #CDDBDF;
  
      &:hover{
        background-color: #DBEBEF;
      }
    }
  
    h5{
      font-family: 'Sofia';
      font-size: 16px;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
      font-weight: 500;
    }
  
  
    .colorTab{
      height: 50px;
      width: 8px;
      border-radius: 4px;
      background-color: #077CA0;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      left:-4px;
    }
  }



  img.close {
    height: 35px;
    width: 35px;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    z-index: 15;
  }

  .swipeCatcher{
    display: none;
  }

  @media (max-width:650px){

    min-width: initial;
    max-width: initial;
    position: relative;
    top: 100%;
    height: 90%;
    transition: .3s all ease;

    .swipeCatcher{
      display: block;
      position: absolute;
      height: 100%;
      width:98%;
      z-index: 2;
      top: 2px;
      left: 1%;
    }

    &.show{
      top:40px
    }

    .close{
      display: none !important;
    }

    .slideHandle{
      display: block;
      height: 5px;
      border-radius: 10px;
      width: 50%;
      margin: 10px auto  30px auto;
      background-color: #cacaca;
    }

    .listDiv{
      height: 100% !important;
    }
    .activityBlock{
      display: none;
    }
    width: 100vw;
  }

}