
.shake{
  -webkit-animation: shake .4s ease-in-out 1.3;
  -moz-animation: shake .4s ease-in-out 1.3;
  -ms-animation: shake .4s ease-in-out 1.3;
  -o-animation: shake .4s ease-in-out 1.3;
  animation: shake .4s ease-in-out 1.3;
}

.shakeSlow{
  -webkit-animation: shake .5s ease-in-out 1;
  -moz-animation: shake .5s ease-in-out 1;
  -ms-animation: shake .5s ease-in-out 1;
  -o-animation: shake .5s ease-in-out 1;
  animation: shake .5s ease-in-out 1;
}

@keyframes shake{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(5px)
  }
  40%{
    transform:translateX(-5px)
  }
  60%{
    transform:translateX(5px)
  }
  80%{
    transform:translateX(-5px)
  }
  100%{
    transform:translateX(0)
  }
}

.shakeSm{
  -webkit-animation: shakeSm .4s ease-in-out 1;
  -moz-animation: shakeSm .4s ease-in-out 1;
  -ms-animation: shakeSm .4s ease-in-out 1;
  -o-animation: shakeSm .4s ease-in-out 1;
  animation: shakeSm .4s ease-in-out 1;
}

@keyframes shakeSm{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(2px)
  }
  40%{
    transform:translateX(-2px)
  }
  60%{
    transform:translateX(2px)
  }
  80%{
    transform:translateX(-2px)
  }
  100%{
    transform:translateX(0)
  }
}

@keyframes pulse {
  0% {
    transform:scale(.9);
  }
  75% {
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  50% {
    transform:scale(1);
  }
    100% {
    transform:scale(.9);
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}

.pulse{
  -webkit-animation: pulse 1s linear infinite;
  -moz-animation: pulse 1s linear infinite;
  -ms-animation: pulse 1s linear infinite;
  -o-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
}
