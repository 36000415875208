@import '.././../../Styles/shared.scss';

.canvasCont{
  width: 45%;
  // transform: scale(.9);
  height: 700px;
  min-height: 700px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  left:20px;
  top:10px;

  .oval,
  .circle{
    position: absolute;
    border-radius: 50%;
    left:50%;
    transform: translateX(-50%);
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 32%);
    z-index: 1;
  }

  .oval{
    height: 700px;
    width: 700px;
    opacity: .3;
    bottom: -220px;
    transform: scale(4, .6);
    left: 0px;
  }

  .circle{
    height: 1300px;
    width: 1300px;
    top: -400px;
    opacity: .7;
  }

  .bucks{
    display: flex;
    align-items: center;
    position: absolute;
    top: 77%;
    z-index: 10;

    h2, span{
      color: white;
      font-size: 28px;
      font-weight: 700;
    }

    span{
      position: relative;
      left: -8px;
      top: 1px;
    }


    img.coins{
      height: 30px;
      width: 30px;
      margin: 0 10px;
      position: relative;
      top: 1px;
      left: -1px;
    }


    &.saving{
      opacity: .5;
    }
  }


  .saveBtn{
    @include solidButton ( white, #077CA0, 20px, 55px, auto);
    padding: 0 55px;
    border:1px solid rgba(255, 255, 255, 0.638);
    box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
    position: absolute;
    top:85%;
    z-index: 10;


    &.saving{
      pointer-events: none;
    }
  }

  img.loader{
    width: 140px;
    height: 140px;
  }
  img.savingLoader{
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 10;
  }

  img.rendered{
    height: auto;
    width: 80%;
    position: relative;
    bottom: 40px;
    left: 10px;
    z-index: 10;

    &.saving{
      opacity: .5;
    }
  }

  .hiddenCanvas{
    display: none;
  }


  @media (max-width:1200px){

    img.rendered{
      width: 90%;
      bottom: 10px;
    }
  }

  @media (max-width:1000px){

    img.rendered{
      bottom: -10px;
    }
    .bucks{
      h2, span{
        font-size: 22px;
      }
    }
  }

  @media (max-width:660px){
    height: 250px;
    min-height: 250px;
    left: 0;
    top: 0;

    .circle{
      height: 800px;
      width: 800px;
      top: -250px;
      opacity: 0.3;
    }

    img.rendered{
      width: 170px;
      bottom: -20px;
    }
    
    .bucks{
      bottom: -20px;
      h2{
        font-size: 15px;
        white-space: nowrap;
      }
      span{
        font-size: 18px;
      }
      img.coins{
        height: 20px;
        width: 20px;
      }
    }

    .saveBtn{
      top: 78vh;
      left: 35vw;
      height: 50px;
      line-height: 50px;
      width: 130px;
      background-color: $E_blue;
      color: white;
      animation: none !important;
      padding: 0;

      span{
        display: none;
      }
    }
  }
}
