
  @mixin mainButton ($color, $bg, $font-size, $height, $width) {
    height:$height;
    width:$width;
    color:$color !important;
    font-size: $font-size;
    background-color: $bg;
    border:1px solid $color;
    text-align: center;
    line-height: $height;
    box-sizing:border-box;
    padding: 0 20px;
    cursor: pointer;
    transition: .3s all ease-out;
    font-weight: 700;
    border-radius: 10px;
    position: relative;
    background-repeat: no-repeat;
    text-decoration: none;
    
    &:hover{
      color:$bg !important;
      background-color: $color;
      background-repeat: no-repeat;
      }
      
  }

  @mixin solidButton ( $bg, $color, $font-size, $height, $width) {
    height:$height;
    width:$width;
    color:$color;
    font-size: $font-size;

    background-color: $bg;
    border:1px solid $bg;
    text-align: center;
    line-height: $height;
    cursor: pointer;
    transition: .3s all ease-out;
    font-weight: 700;
    border-radius: 10px;
    position: relative;
    background-repeat: no-repeat;
    text-decoration: none;
    
    
    &:hover{
      color:$bg;
      background-color: $color;
      border-color: $bg;
      background-repeat: no-repeat;
      }
      
  }


  @mixin dropButton ( $bg, $dropColor, $color, $font-size, $height, $width) {
    height:$height;
    width:$width;
    color:$color;
    font-size: $font-size;
    background-color: $bg;
    border:1px solid $bg;
    text-align: center;
    line-height: $height;
    cursor: pointer;
    transition: .3s all ease-out;
    font-weight: 700;
    border-radius: 10px;
    background-repeat: no-repeat;
    text-decoration: none;
    transform-style: preserve-3d;

    &:after{
      content: '';
      background-color: $dropColor;
      height: $height;
      width: $width;
      border-radius: 10px;
      position: absolute;
      left:0;
      top:5px;
      z-index: 10;
      transform: translateZ(-1px);
    }
    
    &:hover{
      color:$bg;
      background-color: $color;
      border-color: $bg;
      background-repeat: no-repeat;
      }   
  }

  @mixin divDrop($offset, $dropColor){
    position: relative;
    border-radius: 10px;
    &:after{
      content: '';
      background-color: $dropColor;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      position: absolute;
      left:0;
      top:$offset;
      display: block;
      z-index: -1;
    }
  }


