
$main-purple: #4E406E;
$main-purple--dark: #372c4b;
$main-purple--light: #E2D0F6;

$main-blue: #3BB6DD;
$main-blue--light: #26A9D2;
$main-skyBlue: #8FC7F7;

$blue: #065FE5;

$main-turquoise: #1592BE;
$main-teal: #077CA0;
$main-teal--dark: #074B60;

$main-turquise:#074B60;

$main-orange: #FFA826;
$main-orange--light: #FFCB7D;
$main-orange--lighter: #FFEED4;
$main-orange--dark: #A46D1B;

$main-green: #97D318;
$main-green--dark: #7FB114;

$green-story: #C1E574;
$green-story--medium: #D9EFAC;
$green-story--dark: #719E12;
$green-story--light: #EAF6D1;

$blue-assignment: #1385A9;
$blue-assignment--medium: #B2D3DD;
$blue-assignment--dark: #0B546B;
$blue-assignment--light: #D0E9F1;

$orange-journal: #FFCB7D;
$orange-journal--medium: #FFE0B1;
$orange-journal--dark: #8A6834;
$orange-journal--light: #FFEED4;

$E_blue_dark: #074B60;
$E_blue: #077CA0;
$E_blue_light:#E5F2F6;
$E_blue_light2:#B5D8E3;