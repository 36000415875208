@import '../../../Styles/shared.scss';


.container{
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  position: relative;

  *{
    font-family: 'DM Sans';
  }

  &.border{
    border: 1px solid #cacaca;
  }
  .loaderDiv{
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header{
    height: 35px;
    width: 100%;
    background-color: $E_blue_light2;
    display: flex;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .column{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 100%;

      h5{
        font-size: 13px;
        text-transform: uppercase;
        color: $E_blue_dark;
      }

      &:nth-of-type(1){
        justify-content: flex-start;
        box-sizing:border-box;
        padding:0 0 0 75px;
        width: calc(100% - 300px);
  
        .number{
          height: 35px;
          width: 35px;
          border-radius: 5px;
          text-align: center;
          line-height: 35px;
          font-weight: 700;
          color: $E_blue_dark;
          background-color: $E_blue_light2;
          margin-right: 20px;
        }
      }
    }
  }
}