@import '../../../../Styles/shared.scss';

.container{
  background-color: white;
  border-radius: 10px;
  padding: 40px 40px 30px 40px;
  width: 470px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .3s ease all;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // &.confirmation{
  //   width: 400px;
  // }


  img.close{
    position: absolute;
    top:20px;
    right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .images{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 200px;

    img{
      height: 100px;
      margin: 0 5px;
      width: auto;
    }
  }

  .coinsMessage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p{
      color: #858585;
      font-size: 11px;
    }
    h5{
      font-size: 17px;
      margin-bottom: 5px;
    }

    .row{
      display: flex;
      align-items: center;
    }

    img{
      height: 20px;
      position: relative;
      bottom: 2px;
      margin: 0 4px;
    }
  }


  .buttons{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    &.hide{
      display: none
    }

    .btnOne{
      @include solidButton( #CDDBDF, #074B60, 16px, 50px, 190px);
      background: #CDDBDF;
      
      &:hover{
        background:#074B60;
        color:white;
      }
    }
    
    .btnTwo{
      @include solidButton( #074B60, white, 16px, 50px, 160px);
      margin-left: 15px;
      background:#074B60;
      &:hover{
        background: white;
      }
    }

    .disabled{
      opacity: .2;
      pointer-events: none;
    }

  }

  @media (max-width:650px){
    width: 95vw;
    position: relative;
    top: initial;
    bottom: initial;
    right: initial;
    left: initial;
    transform: none;

    .hideRes{
      display: none;
    }
  }
}