.quill{
  height: 100%;
  // border:1px solid purple;

  &.short{
    height: calc(100% - 85px);
  }
}


.ql-container{
  height: calc(100% - 40px) !important; 
  border:1px solid transparent !important; 

}

// ::placeholder
.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 24px;
  font-style: normal;
  line-height: 36px;
  font-weight: 500;
  color: #d2d2d2;
  font-family: 'Gotham';
}

.ql-editor{
  position: relative;
  top: 55px;


  p, li{
    font-size: 17px;
    color: #333333 !important;
  }
}

.approved{
  .ql-toolbar.ql-snow{
    opacity: .4 !important;
    pointer-events: none !important;
  }
}

.ql-toolbar.ql-snow{
  border:1px solid transparent !important;
  width: 500px;
  border-radius: 8px;
  margin-left: 10px;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
  // background-color: white !important;
}

button{
  padding: 5px !important; 
  box-sizing: content-box !important;
  display: grid !important;
  place-items: center !important;

  svg{
    height: 18px;
    width: 18px;
  }
  &.ql-active{
    background-color: #CDDFFA !important;
    border-radius: 8px;

    path{
      stroke: #065FE5 !important;
      color: #065FE5 !important;
    }
  }
}


.ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px !important; 
}


@media (max-width:865px){
  .ql-container{
    height: calc(100% - 60px) !important;
  }
}

@media (max-width:768px){

  .ql-toolbar.ql-snow{
    transform: scale(.9);
    position: absolute;
    z-index: 10;
    left: -20px;
  }

  .ql-editor{
    padding-top: 55px !important; 
  }
}

@media (max-width:720px){
  .ql-toolbar.ql-snow{
    transform: scale(.75);
    left: -60px;
  }

  .ql-container{
    height: 100% !important;
  }
}


///PRIVATE MODE ADJUSTMENTS
.privateModeEntry .quill{

  .ql-toolbar.ql-snow{
    margin-bottom: 50px !important;
  }

  .ql-container{
    height: calc(100% - 110px ) !important; 
  }

  @media (max-width:768px){
    .ql-editor{
      margin-top: 60px;
    }
  }
}


//MOBILE VERSION MEDIA QUERIES

@media (max-width:650px){ 
  .ql-toolbar.ql-snow{
    transform: scale(1);
    position: absolute;
    left: -15px;
    bottom: 5px;
    width: calc(100vw + 10px) !important;
    -webkit-box-shadow: none; 
    box-shadow: none;
    border-top: 1px solid #cacaca !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 !important
  }

  .ql-editor{
    padding-top: 5px !important; 
  }

  // .ql-picker-options{
  //   top:-440% !important;
  // }
}

@media (max-width:336px){
  .ql-toolbar.ql-snow{
    width: 110vw !important;
    min-width: 348px !important;
    transform: scale(0.95);
    left: -25px !important;
  }
}