@import '../../../Styles/shared.scss';

.container{
  width: 100%;
  height: 105px;
  z-index: 15;
  // transition: .6s ease-in-out all;
  // padding-top: 90px;
  // border-bottom: 2px solid transparent;
  position: fixed;

  
  &.open{
    border-bottom: 3px solid #cacaca;
    height: 100vh;
    background-color: white;
  }

  .navBar{
    background-color: $main-teal--dark;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    height: 100px;
    position: absolute;
    top:0;
    left: 0;
    cursor: pointer;

    &.align{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .topRow{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing:border-box;
      padding:0 20px;
      height: 65px;

      img{
        margin-right: 15px;

        &.down{
          transform: rotate(-90deg);
          margin: 0 15px;
        }
      }

      .text{
        width: calc(100% - 20px);;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        *{
          width: fit-content;
        }

        p{
          font-size: 12px;
          line-height: 15px;
          color: #f2f2f2;
        }
      }

      .numberCircle{
        min-height: 20px;
        max-height: 20px;
        min-width: 20px;
        max-width: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #396F80;
        margin-right: 8px;
         p{
           font-size: 12px;
           margin: 0;
           position: relative;
           top: 1px;
           color: white;
           font-weight: 500;
         }
      }

      h5{
        color: white;
        font-weight: 500;
        width: calc(100vw - 50px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .bottomRow{
      box-sizing:border-box;
      padding:0 20px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      // >:first-child{
      //   position: absolute;
      //   left: 15px;
      // }

      // >:last-child{
      //   position: absolute;
      //   right: 15px;
      // }

      .radios{
        display: flex;
        align-items: center;

        label{
          input[type=radio]{
            display: none;
          }

          .radioDiv{
            width: 32vw;
            height: 35px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            &.grey{
              background-color: rgb(194, 194, 194) !important;
            }

            &.slim{
              width: 25vw;
            }

            h6{
              color: white;
              font-size: 12px !important;
              line-height: 14px;
              margin:0;
              text-align: center;

              &.comments{
                width: 70px;
                margin-right: 10px;
              }
            }


            .imgDiv{
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              z-index: 10;
              margin-right: 3px;

              &.notification{
                &:before{
                  content: "";
                  height:10px;
                  width:10px;
                  background-color: red;
                  border-radius: 50%;
                  position: absolute;
                  top: -2px;
                  right: 0px;
                  z-index: 10;
                }
              }
            }

            img{
              height: 15px;
              width: auto;
              margin-right: 4px;
              position: relative;
              
              &.bubble{
                margin-left: 10px;
              }
              &.selected{
                display: none;
              }

              &.boomer{
                height: 35px;
                margin: 0;
                position: relative;
                bottom: 0px;
                right: 5px;
                margin-left: -15px;
              }
            }
          }

          input:checked+.radioDiv{
            background-color: white;

            h6{
              color: $E_blue_dark;
            }

            img.selected{
              display: initial;
            }
            img.unselected{
              display: none;
            }
          }
        }

      }
    }
  }
}