@import '../../../Styles/shared.scss';

.column{
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width:650px){
    box-sizing:border-box;
    padding:0 20px 0 0;
    margin:0;

    .newGroupCont{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.container{
  height: 240px;
  min-width: 240px;
  max-width: 240px;
  // background: #f2f2f2;
  border-radius: 10px;
  position: relative;
  right: 0;
  transition: .3s ease all;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  -moz-box-shadow:    0px 4px 15px -2px rgba(0, 99, 192, 0.3);
  box-shadow:         0px 4px 15px -2px rgba(0, 99, 192, 0.3);

  *{
    transition: .3s ease all;
  }

  &.responsive{
    display: none;
  }

  img.collapse{
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top:6px;
    right:6px;
    opacity: .7;
    z-index: 10;

    &:hover{
      opacity: 1;
    }
  }

  img.boomer{
    width: 235px;
    position: absolute;
    top:55%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }

  .circle{
    background: rgb(12,167,216);
    background: linear-gradient(169deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 100%);
    height: 300px;
    width: 300px;
    border-radius: 50%;
    position: absolute;
    top:115%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &.collapsed{
    height: 120px;
    min-width: 120px;
    max-width: 120px;
    background: transparent;
    margin-left: 5px;
    right: -15px;
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;

    img.collapse{
      top: 3px;
      right: 3px;
      height: 20px;
      width: 20px;
      opacity: 0;
    }

    .circle{
      top:50%;
      width: 100px;
      height: 100px;
    }
    
    img.boomer{
      width: 120px;
    }

    &:hover{
      img.collapse{
        opacity: 1;
      }
    }
  }


  @media (max-width:1120px){
    height: 120px;
    min-width: 120px;
    max-width: 120px;
    background: transparent;
    margin-left: 5px;
    right: -15px;
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;

    img.collapse{
      top: 3px;
      right: 3px;
      height: 20px;
      width: 20px;
      opacity: 0;
    }

    .circle{
      top:50%;
      width: 100px;
      height: 100px;
    }
    
    img.boomer{
      width: 120px;
    }

    &:hover{
      img.collapse{
        opacity: 1;
      }
    }
  }

  // @media (max-width:820px){
  //   position: absolute;
  //   background-color: transparent;
  //   transform: translateX(-50%);
  //   height: 60px;
  //   width: 60px;
  //   left:calc(100% - 60px);
  //   top: 5px;

  //   .circle{
  //     width: 60px;
  //     height: 60px;
  //   }

  //   img.collapse{
  //     display: none;
  //   }

  //   img.boomer{
  //     width: 70px;
  //     top: 40%;
  //   }
  // }

  @media (max-width:650px){
    position: relative;
    transform: translateX(-50%);
    left:50%;
    margin-bottom: 10px;
    
    
  }

}

.backToTeacher{
  width: 100%;
  box-sizing:border-box;
  padding:10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $E_blue_light;
  color: $E_blue_dark !important;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: .3s all ease;
  position: relative;
  top: 3px;

  &:hover{
    background-color: $E_blue_light2;
  }
}


.newGroupCont{
  position: relative;

  .newGroupBtn{
    height: 40px;
    width: 240px;
    border-radius: 10px;
    border:2px dashed  #608E9D;
    stroke-width: 5;
    stroke-dashoffset: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .1s background-color ease-out;
    z-index: 30;
    position: relative;
    margin:10px 0 !important;

    &:hover{
      background-color: $E_blue_light2;
    }

    .plus{
      height: 25px;
      width: 25px;
      background: url('../../../Assets/Icons/plus_blue_light.svg') no-repeat center;
      margin: 0 
    }

    p{
      color: #608E9D;
      font-size: 15px;
      margin:0;
      font-weight: 500;
      position: relative;
      bottom: -1px;
      margin-left: 10px;
      // left: 5px;
    }

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }
  }

}

    
.showModal{
  top: 55px !important;
  opacity: 1 !important;
  pointer-events: all !important;
}