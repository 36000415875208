@import '../../Styles/shared.scss';

.container{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;

  .inner{
    background-color: white;
    width: 450px;
    // height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:30px 50px;
    border-radius: 15px;
    text-align: center;

    .bodyText{
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:15px;
      background-color: #f2f2f2;
      border-radius: 10px;
      margin:20px 0 30px 0;

      .icon{
        height: 35px;
        min-width: 35px;
        max-width: 35px;
        margin-right: 20px;
        background: url('../../Assets/Icons/warning_red.svg') no-repeat center;
        background-size: contain;
      }
      p{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        text-align: left;
        color: #FB3333;
      }
    }

    .dashBtn{
      height: 40px;
      line-height: 40px;
      padding:0 20px;
      border-radius: 8px;
      background-color: $E_blue_light;
      color: $E_blue;
      cursor: pointer;
      transition: .3s all ease;

      &:hover{
        background-color: $E_blue_light2;
      }
    }
  }
}