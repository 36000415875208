@import '../../../../Styles/shared.scss';

.openButtons{
  display: flex;
  justify-content: flex-start;
  padding: 0 5px;
  transition: .5s ease-in all;
  opacity: 0;
  pointer-events: none;
  border-bottom: 1px solid #cacaca;
  margin-bottom: 10px;

  &.noBorder{
    border: none !important;
  }
  
  
  &.display{
    opacity: 1;
    pointer-events: all;
}

  input[type=radio]{
    display: none;
  }

  label{
    height: fit-content;
    min-width: 100px;
    flex: 1 1 0;
    margin: 0 2px;
    width: 25% !important;
    min-width: 25% !important;
    max-width: 25% !important;

    &.wide{
      width: 28% !important;
      min-width: 145px !important;
    }
  }

  .radioOption{
    border-radius: 10px;
    cursor: pointer;
    transition: .3s ease-out all; 
    display: flex;
    // flex-direction: column;s
    align-items: center;
    justify-content: center;
    box-sizing:border-box;
    position: relative;
    // padding:0 3px;
    *{
      pointer-events: none;
    }

    .icon{
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;



    .dot{
      height:10px;
      width:10px;
      border-radius: 50%;
      background-color: red;
      position: absolute;
      top: -1px;
      right: -3px;
    }

      
      img{
        height: 18px;
        position: absolute;
        width: 18px;
        transition: .3s ease-out all;
      }

      img.hover,
      img.selected{
        opacity: 0;
      }

      .boomerCircle{
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border:1px solid transparent;

        img{
          height: 28px;
          width: auto;
          position: relative;
          // bottom: 2px;
        }

        &.one{
          left:5px;
          z-index: 10;
          // border-color:#E9E9E9;
        }
        &.two{
          right:5px;
        }
      }
    }

    &:hover{
      // background-color: #D2D2D2;

      img.init{
        opacity: 0;
      }
      img.hover{
        opacity: 1;
      }
    }

    h5{
      width: calc(100% - 30px);
      font-size: 11px;
      line-height: 35px;
      display: flex;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      // border:1px solid chartreuse;
    }
  }

  input:checked+.radioOption{
    // background-color: #074B60;
    position: relative;

    .icon{
      img.init{
        opacity: 0;
      }
      img.selected{
        opacity: 1;
      }
      // .boomerCircle.one{
      //   border-color:#074B60;
      // }
    }

    h5{
      color: $E_blue;
    }

    &:after{
      position: absolute;
      content:"";
      height: 3px;
      width: 100%;
      background-color: $E_blue;
      bottom: 0;;
    }
  }

  &.isModal{
    label{
      width: 155px !important;
      min-width: 155px !important;
      max-width: 155px !important;
      .radioOption{
        
        width: 150px !important;
      }
    }
  }

  @media (max-width:1110px){
    flex-wrap: wrap;

    label{
      width: 110px !important;
      min-width: 110px !important;
      max-width: 110px !important;
    }

  }
}