@import '../../../Styles/shared.scss';

.winningEntryBtn{
  padding: 10px 15px;
  border-radius: 10px;
  height: fit-content;
  display: flex;
  align-items: center;
  background: rgb(12,167,216);
  background: linear-gradient(169deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 100%);
  cursor: pointer;
  position: relative;
  left: 10px;
  
  h5{
    color: white;
    font-size: 14px;
    margin:0 0 0  10px;
    line-height: 25px;
  }
  
  img{
    height: 20px;
  }
  
  &:hover{
    background: rgb(12,167,216);
    background: linear-gradient(169deg, rgb(12, 157, 201) 0%, rgb(37, 90, 158) 100%);
    // background: linear-gradient(129deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 100%);

  }
  
}

.winningEntryModal{
  height: calc(var(--viewportHeight) - 450px);
  width: 85vw;
  max-height: 1000px;
  min-height: 600px;
  max-width: 1400px;
  background-color: white;
  box-sizing:border-box;
  padding:40px;
  border-radius: 10px;
  position: relative;

  img.close{
    position: absolute;
    top:15px;
    right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .topBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .avatarDiv{
      height: 80px;
      width: 80px;
      margin-right: 15px;

      img{
        height: 100px;
        width: auto;
        position: relative;
        bottom: 8px;
      }
    }

    .text{
      width: calc(100% - 90px);

      .trophyDiv{
        display: flex;
        align-items: center;

        img{
          margin-right: 10px;
        }

        h5{
          color: $main_orange;
          font-size: 16px;
        }
      }
    }
  }

  .editorDiv{
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width:650px){
  .winningEntryBtn{
    h5{
      span{
        display: none;
      }
    }
  }
  
  .winningEntryModal{
    padding:20px 15px;
    width: 95vw;
    height: calc(var(--viewportHeight) - 80px);
    
  
    .editorDiv{
      height: calc(100% - 170px);
    }

    .topBar{
      flex-direction: column;
      margin-bottom: 15px;

      .text{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

}