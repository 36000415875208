@import '../../Styles/shared.scss';

.container{
  height: 100%;
  width: calc(100vw - 90px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing:border-box;
  padding:30px 20px 30px 60px;
  background-color: #f2f2f2;
  overflow: hidden;

  &.boomer{
    background-color: #086E8D;
  }
  
  @media (max-width:660px){
    background-color: #086E8D;
  
  }

  .main{
    width: 50%;
    // max-width: 600px;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 10;

  }

  @media (max-width:865px){
    width: 100vw;
  }

  .resTopBar{
    position: absolute;
    top: 5px;
    width: 100%;
    justify-content: center;
    z-index: 100;
    display: none;

    .viewRadios{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 220px;

      .viewRadio{
        font-size: 12px;
        color: white;
        width: 110px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 30px;
      }

      input:checked + div{
        background-color: $E_blue_dark;
      }
    }

    @media (max-width:660px){
      display: flex;
    }
  }
}

.btn{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  padding: 10px 85px;
  color: white;
  background-color: #A6D130;
  margin-top: 15px;
  cursor: pointer;
  border:1px solid transparent;
  transition: .5s all ease;
  position: relative;
  bottom: 30px;

  &:hover{
    color: #A6D130;
    background-color: white;
    border-color: #A6D130;
  }
}

.grid{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.toggle{
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #074B60;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: .3s all ease;
  z-index: 10;

  &:hover{
    border-color: #074B60;
    background-color: white;
    color: #074B60;

  }
}

.listItem{
  width: 200px;
  border:1px solid #074B60;
  margin: 10px;
  display: flex;
  flex-direction:column;
  align-items: center;
  padding: 15px;

  p{
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }

  img{
    height: 100px;
    max-width: 170px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}


@media (max-width:660px){

  .container{
    width: 100vw;
    padding: 0;
    flex-direction: column-reverse;
  }
  .main{
    width: 100% !important;
  }
}