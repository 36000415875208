@import '../../../Styles/shared.scss';

.container{
  height: 100px;
  width: 100%;
  border:1px solid #e9e9e9;
  border-radius: 8px;
  display: flex;
  margin-top: 8px;
  opacity: 0;transition: .2s all ease-out;
  position: relative;
  pointer-events: all !important;

  &.display{
    opacity: 1;
  }
  
  
  .iconDiv{
    height: 100%;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    img{
      height: 45px;
    }
  }
  
  .text{
    height: 100%;
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing:border-box;
    padding:15px 0;

    h5{
      font-weight: 500;
      font-size: 15px;
      font-family: 'Sofia';
      color: #333333;
      line-height: 22px;
      height: 80px;
      width: 94%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    h6{
      font-weight: 100;
      font-size: 13px;
      font-family: 'Sofia';
      color: #858585;
      height: 20px;
      line-height: 20px;
    }

  }


  .pendingDot{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #FB3333;
    margin-left: 5px;
    position: absolute;
    top:13px;
    right: 8px;

    p{
      margin: 0;
      color: white;
      line-height: 24px;
      font-size: 14px;
      position: relative;
      font-weight: 300;
    }
  }

}