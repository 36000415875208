.topLeft{
  position: absolute;
  top:20px;
  right:50px;
}

.closeCircleRed{
  height: 50px;
  width: 40px;
  background: url('../Assets/Icons/delete_circle_red.svg') no-repeat center;
  position: absolute;
  top:-15px;
  right: -15px;
  cursor: pointer;
}