@import '../../../Styles/shared.scss';

.container{
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;

  &:last-child{
    border:none;
  }

  *{
    font-family: 'DM Sans';
  }

  .column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;
    
    &:nth-of-type(1){
      justify-content: flex-start;
      box-sizing:border-box;
      padding:0 0 0 20px;
      width: calc(100% - 300px);

      .number{
        height: 35px;
        width: 35px;
        border-radius: 5px;
        text-align: center;
        line-height: 35px;
        font-weight: 700;
        color: $E_blue_dark;
        background-color: $E_blue_light2;
        margin-right: 20px;
        font-size: 18px;
      }
    }
    p.count{
      font-size: 14px;
      font-weight: 300;
      color: $E_blue_dark;
    }

    p.title{
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      color: #4e4e4e;

    }
  }

  &.submitted{
    background-color: #FCFFF5;

    .number{
      background-color: #E3F1C0 !important;
      color: #858585 !important;
    }
  }
}