@import '../../../Styles/shared.scss';

.container{
  height: 105px;
  min-height: 105px;
  max-height: 130px;
  border-radius: 10px;
  flex-grow: 1;
  max-width: 355px;
  min-width: 355px;
  margin: 10px 5px 5px 5px;
  position: relative;
  cursor: pointer;
  transition: .1s ease-in all, .2s cubic-bezier(0.2,-0.1, 0.5, 2.5) bottom;
  opacity: 0;
  // bottom:25px;
  border-radius: 10px;
  border: 1px solid #cacaca;
  box-sizing:border-box;
  padding:5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .iconDiv{
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .text{
    width: calc(100% - 85px);
  }

  *{
    font-family: 'Sofia';
  }

  &.display{
    height: 105px;
    bottom:0;
    opacity: 1;
  }


  &:hover{
    bottom: 3px;
    -webkit-box-shadow: 0px 5px 10px -2px rgba(6, 0, 0, .3);
    -moz-box-shadow:    0px 5px 10px -2px rgba(6, 0, 0, .3);
    box-shadow:         0px 5px 10px -2px rgba(6, 0, 0, .3);

    // .imgDiv{

    //   img.icon{
    //     top: -22px;
    //     opacity: 1;
    //   }
    // }
  }

  &.blue{
    background-color: #CDDBDF;

    &:hover{
      background-color: #DBEBEF;
    }
  }

  h5{
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
    height: calc(100% - 50px);
  }

  h6{
    color: #858585;
    font-weight: 300 !important;
  }


  .dot{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #fb3333;
    color: white;
    margin-left: 5px;
    position: absolute;
    top:-10px;
    right: -10px;

    p{
      margin: 0;
      color: white;
      line-height: 20px;
    }
  }


 .results{
   height: 30px;
   h6{
     font-size: 14px;
     font-weight: 300;
     font-style: italic;
     color: #97D318;
   }
  }

  .tabDiv{
    position: absolute;
    left:0;
    top: 0;
    height: 100%;
    width: 4px;
    overflow: hidden;
    
    .colorTab{
      height: 60px;
      width: 8px;
      border-radius: 4px;
      background-color: #077CA0;
      position: relative;
      top:20px;
      left:-4px;
    }

  }
  
  // .imgDiv{
  //   position: absolute;
  //   top: 28px;
  //   right: 10px;
  //   height: 100px;
  //   width: 100px;
  //   overflow: hidden;

  //   img.icon{
  //     opacity: .5;    
  //     position: absolute;
  //     top: -5px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     transition: .3s all ease;
  //   }
  // }



  @media (max-width:650px){
    width: 100%;
    max-width: initial;
    mIN-width: initial;
    display: flex;
    align-items: center;

    h5{
      height: fit-content !important;
      min-height: none !important;
      max-height: calc(100% - 50px);
      font-size: 18px;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.short,
      &.medium{
        min-height: none !important;
      }
    }

    .results{
      display: none;
    }

    .colorTab{
      height: 80px;
      width: 12px;
      top:50%;
      transform: translateY(-50%);
      left:-7px;
    }
  }


  // @media (max-width:1150px){
  //   width: 48%;
  //   max-width: 48%;
  //   min-width: initial;
  //   margin: 1% 1% 10px 1%;
  // }

  // @media (max-width:860px){
  //   width: 100%;
  //   max-width: 100%;

  //   h4{
  //     width: 75%;
  //     max-width: initial;
  //   }
  
  // }

  // @media (max-width:450px){
  //   .pill{
  //     display: none !important;
  //   }

  //   .deadline{
  //     left: initial !important;
  //     bottom:initial !important;
  //     right: 10px;
  //     top:5px;

  //   }

  //   .boomer{
  //     margin-top: 10px;
  //     margin-bottom: 10px;
  //   }

  // }
}