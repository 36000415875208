@import '../../../Styles/shared.scss';

.container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;


  h4{
    color: white;
    padding: 6px 20px 5px 20px;
    border-radius: 8px;
    font-size: 15px;
    white-space: nowrap;
    
    &.orange{
      background: rgb(255,168,38);
      background: linear-gradient(139deg, rgba(255,168,38,1) 0%, rgba(255,116,38,1) 100%);
    }
  
    &.orangeTwo{
      background: #FFC226;
      background: linear-gradient(139deg, #FFC226 0%, #DC7C0C 100%);
    }

    &.green{
      background: url('../../../Assets/Icons/tick_green.svg') no-repeat right 10px center;
      background-color:#EAF6D1;
      color: #608316;
      padding-right: 35px;
      font-weight: 300;
    }
  }
}