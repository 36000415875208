@import '../../../Styles/shared.scss';


  .activityBlocks{
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    // width: fit-content;
    // width: 100%;
    position: absolute;
    opacity: 1;
    transition: .3s all ease;
    width: 100%;

    &.hide{
      opacity: 0;
    }

    
    &.centered{
      height: calc(100vh - 100px);
      width: calc(100vw - 200px);
      justify-content: center;
      align-items: center;
      box-sizing:border-box;
      padding:0 0 140px 0;
    }

    .noActivities {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 350px;
      min-height: 480px;
      max-width: 750px;
      width: 70vw;
      background-color: white;
      border-radius: 10px;
      position: relative;

      img {
        width: 180px;
        margin-bottom: 20px;
      }

      h3 {
        width: 350px;
        text-align: center;
        margin: 15px 0;
        font-size: 25px;
        line-height: 35px;
        color: $main-purple--dark;
      }

      .goBackBtn {
        height: 50px;
        width: 280px;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: white;
        justify-content: center;
        border-radius: 8px;
        background-color: $E_blue;
        cursor: pointer;
        transition: .15s all ease-out;
        
    
        &:hover {
          background-color: $E_blue_dark;
        }
      }

    }

  }


  h6.emptyTxt{
    width: 100%;
    text-align: center;
    color: #077B9E;
    font-weight: 100;
    margin-top: 50px;
    font-size: 15px;
  }

  .responsive{
    display: none;

    h4{
      color: #333333;
      position: relative;
      top: 10px;
      margin-bottom: 20px;

      &:last-of-type{
        margin-top: 30px;
      }
    }
  }

  @media (max-width:650px){
    .activityBlocks{
      display: none;
    }

    .responsive{
      display: initial;
    }
  }
