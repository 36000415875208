@import '../../../../../Styles/shared.scss';

.container{
  margin-bottom: 10px;
  display: flex;



  &.studentComment{
    justify-content: flex-end;

    .commentBubble{
      background-color: $E_blue_light2 ;
      border-radius: 10px 10px 0px 10px;

      .dateTimeDiv{
        p{
          color:  $main-teal !important;
        }
      }

      p.bodyText{
        color: #074B60;
      }
    }
  }

  .commentBubble{
    height: fit-content;
    min-height: 70px;
    padding:10px 20px 15px 20px;
    width: 370px;
    border-radius: 10px 10px 10px 0;
    background-color: #e9e9e9;
    position: relative;

    p.bodyText{
      font-size: 14px;
      font-weight: 900;
      color: #333333;
    }

    p.teacherName{
      font-size: 10px;
      position: absolute;
      right: 15px;
      opacity: .4;
      bottom: 5px;
    }
    
    .dateTimeDiv{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      
      p.dateTime{
        font-size: 10px;
        color: #858585;
      }
    }

    &.revisionNonSubmitted{
      background-color: #F4BFBF;
      p{
        color: #fb3333 !important;
      }
    }
    
    &.revisionSubmitted{
      background-color: #CDE5EC;
      p{
        color: #077CA0 !important;
      } 
    }
    
    &.final{
      background-color: #EAF6D1;
      p{
        color: #425B0C !important;
      }
    }

    
  }
  
  img.icon{
    margin-left: 10px;
  }
  
  &.move{
    transition: .7s cubic-bezier(0.55, -0.19, 0.36, 1.38) all;
    transform: translateY(0px) !important;
  }

  @media (max-width:1024px){
    .commentBubble{
      width: calc(100% - 35px);
    }
  }
}