@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');


@font-face {
  font-family: 'Gotham';
  src: url('../Assets/Fonts/GOTHAMROUNDEDLIGHT.TTF');
  font-weight: 100;
}

@font-face {
  font-family: 'Gotham';
  src: url('../Assets/Fonts/GOTHAMRND-BOOK.OTF');
  font-weight: 300;
  }

@font-face {
  font-family: 'Gotham';
  src: url('../Assets/Fonts/GOTHAMRND-MEDIUM.OTF');
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';
  src: url('../Assets/Fonts/GOTHAMRND-BOLD.OTF');
  font-weight: 700;
}


@font-face {
  font-family: 'Sofia';
  src: url('../Assets/Fonts/Sofia-Pro-Regular.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Sofia';
  src: url('../Assets/Fonts/Sofia-Pro-Semi-Bold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Sofia';
  src: url('../Assets/Fonts/Sofia-Pro-Bold.otf');
  font-weight: 700;
}


// @font-face {
//   font-family: 'DM sans';
//   src: url('../Assets/Fonts/DM_Sans/DMSans-Regular.ttf');
//   font-weight: 300;
// }

// @font-face {
//   font-family: 'DM sans';
//   src: url('../Assets/Fonts/DM_Sans/DMSans-Medium.ttf');
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'DM sans';
//   src: url('../Assets/Fonts/DM_Sans/DMSans-Bold.ttf');
//   font-weight: 700;
// }