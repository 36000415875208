@import '../../Styles/shared.scss';

.container{
  height: 85vh;
  min-height: 400px;
  width: 85vw;
  min-width: 700px;
  border-radius: 10px;
  background-color: white;
  position: relative;
  box-sizing:border-box;
  padding:15px;
  display: flex;
  justify-content: space-between;

  img.close {
    height: 35px;
    width: 35px;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    z-index: 15;
  }

  .chats{
    height: calc(100% - 10px) !important;
    width: 30%;
    border-radius: 10px;
    position: relative;
    bottom: 5px;

    .loaderDiv{
      height: 150px;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);

      img{
        width: 90px;
        height: 90px;
      }

      p{
        font-size: 12px;
        margin: 0;
        position: relative;
        bottom: 15px;
      }
    }

    input[type=radio]{
      display: none;
    }

    .chatRadio{
      width: 100%;
      height: 70px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:10px;
      margin-bottom: 3px;
      cursor: pointer;
      transition: .3s all ease-out;

      *{
        transition: .3s all ease-out;
      }

      .avatarDiv{
        width: 50px;
        height: 50px;
        position: relative;
        margin-right: 15px;

        .unreadCount{
          height:20px;
          width: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fb3333;
          border:1px solid white;
          position: absolute;
          top: -5px;
          right: -8px;
          
          p{
            color: white;
            font-size: 12px;
            margin: 0;
          }
        }

        img{
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      h4{
        color: #518190;
        font-weight: 500;
      }

      &:hover{
        background-color: #CDDBDF;

        h4{
          color: #084B60;
        }
      }

      @media (max-width:650px){
        border-radius: 0;
        border-bottom: 1px solid #5996a833;
      }
    }

    input:checked+.chatRadio{
      background-color: #084B60;
      h4{
        color: white;
      }

      @media (max-width:650px){
        background-color: white;

        h4{
          color: #518190;
        }
      }
    }
  }

  .messages{
    height: 100%;
    width: calc(70% - 10px);
    border-radius: 10px;
    background-color: #E5F2F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:10px;

    .resTopBar{
      display: none;
    }
  }

  .messagesInner{
    width: 100%;
    max-height: calc(100% - 75px);
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;

    .dayPill{
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      .line{
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #BADAE4;
        z-index: 1;
        top: 50%;

      }

      .inner{
        position: relative;
        z-index: 10;
        top: 2px;
        padding:4px 15px 1px 15px;
        border-radius: 50px;
        background-color: #8BC1D3;
        color:#2F6E82;
        font-size: 13px;
        text-align: center;
        margin-bottom: 5px;
        border: 5px solid #E4F2F5;
      }
    }

    .msgContainer{
      display: flex;
      align-items: center;
      height: fit-content;
      margin-bottom: 10px;

      img{
        height: 60px;
        width: 60px;
        border-radius: 50%;
        object-fit: cover;
      }

      .msgDiv{
        background-color: white;
        padding:10px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        color: #518190;
        margin-left:10px;
        position: relative;
        margin-bottom: 20px;
        min-width: 150px;

        p{
          position: absolute;
          bottom: -20px;
          left: 0;
          font-size: 10px;
          margin: 0;
          color: #769FAB;
          min-width: 150px;
          width: 100%;
        }        
      }

      &.student{
        flex-direction: row-reverse;

        .msgDiv{
          background-color: #084B60;
          color: white;
          margin-left: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 0;
          text-align: right;

          p{
            text-align: right;
          }
        }
      }
    }
  }

  .inputDiv{
    height: 70px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:10px 8px;

    .messageInputDiv{
      height: 55px;
      width: calc(100% - 120px);
      border:6px solid #E5F2F6;
      border-radius: 15px;
      padding: 0;
    }

    input[type=text]{
      height: 100%;
      width: 100%;
      border-radius: 7px;
      background-color: white;
      border:1px solid #3FAACC;
      box-sizing:border-box;
      font-size: 18px;
      padding:0 10px;
    }

    .sendBtn{
      @include solidButton(#077CA0, white, 16px, 36px, 100px);
    }
  }

  @media (max-width:650px){
    position: relative;
    width: 95vw;
    min-width: initial;
    height: calc(var(--viewportHeight) - 110px);
    margin-bottom: 75px;
    overflow: hidden;


    img.close {
      top: 5px;
      right: 5px;
      z-index: 100;
    }

    .chats{
      position: absolute;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      background-color: white;
      z-index: 50;
      left: -100%;
      transition: .3s all ease-in-out;

      &.active{
        left: 15px;
      }
    }
    .messages{
      width: 100%;
      padding: 0;

      .resTopBar{
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: white;
        border:1px solid $main_turquoise;
        border-radius: 10px;
        position: relative;
        bottom: 6px;
        transform-style: preserve-3d;

        &::after{
          content: "";
          height: calc(100% + 10px);
          width: calc(100% + 10px);
          position: absolute;
          left: -5px;
          top: -5px;
          background-color: white;
          transform: translateZ(-1px);
          border-radius: 10px;
        }

       h5{
         color: $main-teal;
         margin-left: 10px;
       }

       img.avatar{
         min-height: 45px;
         max-height: 45px;
         min-width: 45px;
         max-width: 45px;
         border-radius: 50%;
         object-fit: cover;
       }

        img.arrow{
          height: 25px;
          margin-right: 15px;
          margin-left: 5px;
        }
      }

      .messagesInner{
        box-sizing:border-box;
        padding:0 10px;
        height: calc(100% - 80px);
      }

      .inputDiv{
        padding:5px 0 0 0 !important;
        .messageInputDiv{
          width: calc(100% - 60px);
        }

        .sendBtn{
          width: 40px;
          background: url('../../Assets/Icons/icon-sendMsg.svg') center no-repeat;
          background-size: 100%;
          position: relative;
          right: 3px;

          span{
            display: none;
          }
        }
      }
    }
  }
}