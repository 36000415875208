@import '../../../Styles/shared.scss';


.container{
  height: 90vh;
  width: 90vw;
  max-width: 1400px;
  background-color: $E_blue_light;
  border-radius: 10px;
  box-sizing:border-box;
  padding:30px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  // justify-content: space-between;

  *{
    font-family: 'Sofia';
  }


  img.close{
    position: absolute;
    top:20px;
    right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }


  h1{
    font-size: 30px;
    margin-bottom: 20px;
  }
  .blocks{
    width: 100%;
    max-height: calc(100% - 70px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    overflow-y: auto;

    .block{
      height: 230px;
      width: 250px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      margin-bottom: 20px;
      cursor: pointer;

      h5{
        margin:10px 0;
        position: relative;
        bottom: 10px;
        opacity: 0;
        transition: .15s all ease-out;
      }

      img{
        height: 200px;
        position: relative;
        top: 10px;
        object-fit: contain;
      }

      &:hover{
        h5{
          opacity: 1;
        }
      }
    }
  }
}