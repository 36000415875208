@import '../../../Styles/shared.scss';

.sectionList{
  width: 100%;
  // height: 100%;
  height: 100%;
  border: 1px solid #cacaca;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  position: relative;

  .header{
    height: 35px;
    width: 100%;
    background-color: #4A7988;
    display: flex;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .column{
      h5{
        max-width: 100% !important;
        overflow: initial !important;
        text-overflow: initial !important;
        white-space: initial !important;
      }

    }


  }
   
  .column{
    width: 10%;
    text-align: center;
    box-sizing:border-box;
    
    h5{
      color: white;
    }

    &:first-of-type{
      text-align: left;
      width: 30%;
      padding:0 80px;

      h5{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 50px);
        white-space: nowrap;
      }
    }
    &:last-of-type{
      text-align: right;
      width: 20%;
      padding:0 80px;
    }
  }

  .header.synod{
    .column{
      width: 150px ;
      padding:0 ;
      
      &:first-of-type{
        width: calc(100% - 300px);
        padding:0 80px;
      }
      &:last-of-type{
        min-width: none;
        text-align: center;
      }
    }
  }

  .rowsDiv{
    border-radius: 15px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    overflow: auto;
    height: calc(100% - 35px);
    padding: 0 !important;

    &.main{
      max-height: calc(100vh - 240px);
    }


    .sectionRow{
      display: flex;
      align-items: center;
      height: 80px;
      border-bottom:1px solid #cacaca;
      cursor: pointer;

      // &.finished{
      //   background-color: #F2F2F2;
      // }


      .column{
        display: flex;
        justify-content: center;
        align-items: center;
        
        h5{
          color: #074B60;
        }

        p.grade{
          color: #065FE5;
          font-size: 15px;
          font-weight: 700;
        }

        .numberDiv{
          min-height: 70px;
          max-height: 70px;
          min-width: 60px;
          max-width: 60px;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          background: url('../../../Assets/Icons/book_icon_greyblue.png') no-repeat center left 11px;

          p{
            margin: 0;
            color: #074B60;
            font-size: 16px;
            position: relative;
            top: 1px;
            font-weight: 700;
          }
          
          // &.finished{
          //   background: url('../../../Assets/Icons/book_icon_grey.png') no-repeat center left 11px;

          //   p{
          //     color: #4e4e4e;
          //   }

          // }
        }
        
        &:first-of-type{
          justify-content: start;
          padding:0 20px;
        }
        
        &:last-of-type{
          justify-content: flex-end;
          padding:0 20px;

          img.icon{
            margin: 0 8px
          }

          .messages{
            position: relative;
            top: 3px;
            margin: 0 8px;
            img{
              width: 26px;
              height: auto;
              position: relative;
              top: 3px;
              right: 5px;
            }
            .count{
              min-height: 17px;
              max-height: 17px;
              min-width: 17px;
              max-width: 17px;
              border-radius: 50%;
              background-color: #FB3333;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top:-2px;
              right: -2px;

              p{
                color: white;
                font-size: 11px;
              }
            }
          }
        }

      }
      &.synod{
        .column{
          width: 150px !important;
          
          &:first-of-type{
            width: calc(100% - 300px) !important;
          }
          &:last-of-type{
            min-width: none !important;
          }
        }
      }

      // &:last-of-type{
      //   border-color: transparent !important;
      // }
    }

    
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
      height: calc(100% - 20px);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #c4c4c4;
    }
  
  }


  @media (max-width:650px){
    width: calc(100% - 10px);
    margin: 0 auto;

    

    .header{
      display: none;
    }

    .rowsDiv{
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      margin: 0 auto;
    }


        
    .column{

      h5{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:first-of-type{
        width: calc(100% - 90px);

        .numberDot{
          display: none !important;
        }

        h5{
          max-width: calc(100% - 115px);
        }
        
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4){
        display: none;
        width: 0;
        *{
          display: none;
        }
      }
              
      &:last-of-type{
        width: 80px !important;
        min-width: initial !important;
        padding-right: 0 !important;

        .messages{
          margin-right: 15px !important;
        }
      }
    }
  }

}