@import '../../../../Styles/shared.scss';

.variantSelector{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  box-sizing:border-box;
  width: 100%;


  .close{
    height: 30px;
    width: 30px;
    position: absolute;
    top: 5px;
    right:5px;
    cursor: pointer;
  }

  .top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;

    img.boomer{
      height: 50px;
      margin-right: 20px;
    }

  }

  .purchaseText{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    
    .title{
      display: flex;
      align-items: center;

      img.boomer{
        width: 36px;
        position: relative;
        bottom: 3px;
        height: auto;
        margin-right: 15px;
      }
    }

    h4{
      margin-top: 10px;
      width: 75%;
      text-align: center;
      line-height: 25px;
    }
  }

  .buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;

    .cancelBtn{
      color: #077CA0;
      font-weight: 500;
      cursor: pointer;
      padding: 0 15px;
      margin: 0 10px;

      &:hover{
        text-decoration: underline;
      }
    }

    .confirmBtn{
      @include solidButton ( #077CA0, white, 15px, 40px, auto);
      padding: 0 20px;
    }
  }

  .postPurchase{
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    // margin-left: 2.7vw;

    &.failure{
      margin-top: 120px;
      justify-content: center;

      img.face{
        margin-bottom: 30px;
      }
    }

    h3{
      width:300px;
      text-align: center;
    }

    img.tick{
      height: 100px;
      margin: 20px 0;
    }


    .okBtn{
      @include solidButton ( #077CA0, white, 15px, 40px, auto);
      padding: 0 20px;
      margin-top:70px;
    }
  }
}