@import '../../../Styles/shared.scss';

.container{
  box-sizing:border-box;
  padding:20px 40px;
  min-height: 85px;
  min-width: 100px;
  height: 360px;
  flex: 1 1 0;
  margin: 0 8px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.1);

  .header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img{
      height: 25px;
    }
  }

  .entryText{
    margin-top: 45px;
    height: 180px;
    overflow: hidden;
    position: relative;
    
    *{
      font-weight: 100;
      font-size: 14px;
    }

    // &:after{
    //   content:"";
    //   height:100%;
    //   width:100%;
    //   position: absolute;
    //   transform: rotate(180deg);
    //   top:0;
    //   left: 0;
    //   background: rgb(0,0,0);
    //   background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
    // }
  }

  .buttons{
    display: flex;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;

    .readBtn{
      @include solidButton( #9CCAD9, #077CA0, 16px, 40px, 100px);
      &:hover{
        background-color: white;
        color: #077CA0;
      }
    }

    .voteBtn{
      @include solidButton( #2B4E7B, white, 16px, 40px, 100px)
    }

    .disabled{
      pointer-events: none;
      opacity: .3;
    }
  }

  @media (max-width:1024px){
    margin-bottom: 20px;
  }

  &.mobile{
    padding: 20px;
    flex: initial;
    width: 80vw;
  }

}