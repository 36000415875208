
.sectionStatusPill{
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;

  .dot{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #fb3333;
    position: absolute;
    top: -3px;
    right: -3px;
  }
  
  img{
    margin-right: 10px;
    height: 30px;
  }
  
  h4{
    color: white;
    padding: 6px 20px 5px 20px;
    border-radius: 8px;
    font-size: 14px;
    white-space: nowrap;


    &.tall{
      padding: 12px 20px !important;  
      transition: .15s all ease;
      position: relative;
      bottom: 0;

      &:hover{
        bottom:3px;
        -webkit-box-shadow: 0px 4px 15px -2px rgba(6, 0, 0, 0.3);
        -moz-box-shadow:    0px 4px 15px -2px rgba(6, 0, 0, 0.3);
        box-shadow:         0px 4px 15px -2px rgba(6, 0, 0, 0.3);}
    }
    
      &.orange{
        background: rgb(255,168,38);
        background: linear-gradient(139deg, rgba(255,168,38,1) 0%, rgba(255,116,38,1) 100%);
      }
    
      &.orangeTwo{
        background: #FFC226;
        background: linear-gradient(139deg, #FFC226 0%, #DC7C0C 100%);
      }

      &.pink{
        background: rgb(242,98,131);
        background: linear-gradient(139deg, rgba(242,98,131,1) 0%, rgba(254,135,95,1) 100%);
      }

      &.purple{
        background: rgb(6,95,229);
        background: linear-gradient(180deg, rgba(6,95,229,1) 0%, rgba(142,0,240,1) 66%);
      }

      &.grey{
        background-color:#B2BCCB ;
      }

      &.opaque{
        background-color:#c3c0caab ;
        color: white;
      }

      &.noWrite{
        color: #cacaca;
      }
  }
}


.approved{
  display: flex;
  align-items: center;
  margin: 0 8px;
  h6{
    color: #B2AFB9;
    font-size: 13px;
    margin-right: 3px;
  }

}