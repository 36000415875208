@import '../../../../Styles/shared.scss';

.container{
  background-color: white;
  border-radius: 10px;
  padding: 40px 40px 30px 40px;
  width: 470px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .3s ease all;
  text-align: center;
  -webkit-box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.05); 
  box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.05);
  position: relative;
  bottom: 30px;
  opacity: 0;

  &.display{
    opacity: 1;
  }

  &.mobile{
    height: 60vh;
    width: 100vw;
    -webkit-box-shadow: none; 
    box-shadow: none;
    bottom: 10%;

  }


  img.close{
    position: absolute;
    top:20px;
    right: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .images{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    width: 200px;

    img{
      height: 100px;
      margin: 0 5px;
      width: auto;

      &.icon{
        height: 85px;
        position: relative;
        top:10px;
      }
    }
  }

  .coinsMessage{
    display: flex;
    align-items: center;

    img{
      height: 20px;
      position: relative;
      bottom: 2px;
      margin: 0 4px;
    }
  }


  .buttons{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    &.hide{
      display: none
    }

    .btnOne{
      @include solidButton( #CDDBDF, #074B60, 16px, 50px, 190px);
      background: #CDDBDF;
      
      &:hover{
        background:#074B60;
        color:white;
      }
    }

    .btnTwo{
      @include solidButton(#074B60, #CDDBDF,  16px, 50px, 140px);
      border-color: transparent;
      color: white;
      margin-left: 10px;
      
      &:hover{
        border-color: transparent;
        color:#074B60;
        background:#CDDBDF;
      }
    }

    .disabled{
      opacity: .2;
      pointer-events: none;
    }

  }
}