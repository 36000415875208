@import '../../../../Styles/shared.scss';

.container{
  width: 90vw;
  max-width: 1400px;
  height: 90vh;
  background-color: white;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;


  img.close{
    position: absolute;
    top:10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    z-index: 100;
  }


  h4.synodHeading{
    font-family: "DM Sans";
    background: url('./../../../../Assets/Icons/instructions_blue_dark.svg')no-repeat left center;
    // background: url('../../../../../Assets/Icons/instructions_blue_dark.svg')no-repeat left center;
    background-size: 20px;
    padding-left: 30px;
    margin-left: 10px;
    padding-top: 2px;
    color: $E_blue_dark;
    font-size: 20px;
    position: relative;
    top: 10px;
    left: 15px;
  }



  .topBar{
    position: relative;
    width: 100%;
    padding: 5px 10px 0 10px;
    border-bottom: 1px solid #cacaca;

    .buttonsDiv{
      width: 550px;
      height: 35px;
      position: relative;
      bottom: -1px;
    }
    
  }

  .mainSwitch{
    width: 100%;
    height: calc(90vh - 90px);
    box-sizing:border-box;
    padding:5px 10px;
  }

}