@import '../../../Styles/shared.scss';

.container{
  height: 400px;
  width:450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  position: relative;

  h1{
    font-size: 30px;
  }

  p{
    text-align: center;
    color: #858585;
  }

  img{
    height: 170px;
    margin-bottom: 20px;
  }

  img.close{
    height: 25px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}