@import '../../Styles/shared.scss';

.navContainer{
  width: 85px;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
  background-color: $main-teal--dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 5px 0;
  position: relative;
  z-index: 20;
  // bottom: 3px;
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);

  .navTop,
  .navBottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width:865px){
      flex-direction: row;
    }
  }

  .userDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 5px 0;
    // box-sizing:border-box;
    // padding:15px 0;

    .coinsDiv{
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #8585856e;
      border-bottom: 1px solid #8585856e;
      margin-top: 5px;
      
      img{
        height: 17px;
        margin-right: 7px;
      }
    }
    h6.count{
      font-size: 11px;
      overflow: visible;
      color: #FCC62D;
    }
    h6.welcome{
      margin:0 !important;
      line-height: 17px;
      font-size: 12px;
      color: #FCC62D;
    }

  }

  .navLink{
    position: relative;
    height: 100px;
    // width: 85px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.borderBtm{
      border-bottom:1px solid #8585856e;

      @media (max-width:865px){
        border-bottom: 1px solid transparent;
        border-right:1px solid #8585856e;
      }

    }
    &.borderLeftRes{
      @media (max-width:865px){
        border-left:1px solid #8585856e;
      }
    }

    *{
      position: relative;
      z-index: 5;
    }

    &:before{
      content: '';
      height: 100%;
      width: 100px;
      position: absolute;
      background-color: #00A5FF;
      top:0;
      left:-140px;
      transition: .15s ease-in all;
      z-index: 1;
    }


    &.activeNav::before{
      left:-123px;
    }

    &:hover:before{
      left:-15px;
    }


    img{
      margin-bottom: 5px;
      width: 35px;
    }

    .boomer{
      position: relative;
      height: 70px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      // &:before{
      //   content: "";
      //   position: absolute;
      //   height: 55px;
      //   width: 55px;
      //   top:5px;
      //   left:50%;
      //   transform: translateX(-50%);
      //   background-color: $main-orange;
      //   border-radius: 50%;
      // }      
      img.boomerImg{
        margin-bottom: 0px;
        height: 50px;
        width: auto;

        &.large{
          transform: scale(1.75);
          position: relative;
          top: 3px;
        }
      }
    }


    h5{
      color:$main-orange--light;
      font-size: 12px;;
      line-height: 20px;
    }


    .unreadCount{
      height:20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fb3333;
      border:1px solid white;
      position: absolute;
      top: 13px;
      right: 8px;
      z-index: 10;
      
      p{
        color: white;
        font-size: 12px;
        margin: 0;
      }

      @media (max-width:650px){
        right: 23px;
        top: 5px;
      }
    }

    &.resChat{
      img{
        width: 40px;
        position: relative;
        bottom: 5px;
      }
    }


    &.mobileOnly{
      display: none;
    }
  }

  hr {
    border: none;
    height: 1px;
    width: 100%;
    background-color: #70707088;
    // margin:2px 0;
  }

  h6:last-child{
    font-size:13px;
    color:#ffffff70;
    font-weight: 500;
  }
}

@media (max-width:865px){

  .navContainer{
    flex-direction: row;
    height: 90px;
    align-items: center;
    width: 100%;
    min-height: 0px;
    // padding:0;
    // bottom: 0;

    .navTop,
    .navBottom{
      flex-direction: row;
    }

    .navLink{
      // border:1px solid chartreuse;
      height: 90px;
      width: 130px;
      overflow: hidden;
      box-sizing:border-box;
      padding:0px;
      justify-content: center; 
      align-items: center;

      *{
        padding: 0 !important;
        margin: 0 !important;
      }

      &::before{
        display: none;
      }

      img{
        height: 45px;
        // border:1px solid chartreuse;
      }

      &#profile{
        padding-bottom: 10px;
        justify-content: initial;
        align-items: center;

        h5{
          position: relative;
          bottom: 3px;
        }
        // img{
        //   position: relative;
        //   bottom: 1px;
        // }

        .boomer{
          &:before{
            height: 45px;
            width:45px;
            top:5px;
          }
          img.boomerImg{
            height: 55px;
          }
        }
      }

    }
  }

}

@media (max-width:650px){
  .navContainer{
    position: relative;
  }

  .navLink{
    &.desktopOnly{
      display: none;
    }

    &.mobileOnly{display: flex !important}
  }
}

@media (max-width:650px){
.navContainer.entry{
  display: none;
}
}

@media (max-width:550px){
  .navContainer{

    .navLink{
      width: 25vw;
    }
  }
}