   
  .auto_grammar{
    border:1px solid  #8e00f0;
    background-color: #8e00f04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_consistency{
    border:1px solid  #FF8258;
    background-color: #FF82584a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
   
  .auto_overused{
    border:1px solid  #077ca0;
    background-color: #077ca04a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_initial{
    border:1px solid  #ffa826;
    background-color: #ffa8264a;
    height:24px;
    line-height: 20px;box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }

  .auto_passive{
    border:1px solid  #f26283;
    background-color: #f262834a;
    height:24px;
    line-height: 20px;
    box-sizing:border-box;
    padding:1px 5px 3px 7px;
    border-radius: 30px;
  }
  

  .feedback{
    cursor: pointer;

    .tooltipWrapper{
      position: absolute;
      visibility: hidden;
     left: calc(50% - 100px);
    }
    
    .tooltip{
      position: absolute;
      top:15px;
      left: 0;
      right: 0;
      height:fit-content;
      height:-moz-max-content;
      width:200px;
      width:200px;
      min-width: 160px;
      box-sizing:border-box;
      padding:10px;
      border-radius: 5px;
      color: #858585;
      background-color: white;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      z-index: 1000;
    }

    .sugg_grammar{
      font-size: 12px;
      font-weight: 500;
      color: #8e00f0;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#8e00f0;
      }
    }

    .sugg_consistency{
      font-size: 12px;
      font-weight: 500;
      color: #FF8258;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#FF8258;
      }
    }

    .sugg_overused{
      font-size: 12px;
      font-weight: 500;
      color: #077ca0;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#077ca0;
      }
    }

    .sugg_passive{
      font-size: 12px;
      font-weight: 500;
      color: #f26283;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#f26283;
      }
    }

    .sugg_initial{
      font-size: 12px;
      font-weight: 500;
      color: #ffa826;
      .suggHead{
        font-size: 12px;
        font-weight: 700;
        display: block;
        color:#ffa826;
      }
    }


    
    &:hover >.tooltipWrapper{
      visibility: visible;
    }

  }
