@import '../../../../../Styles/shared.scss';

.container{
  width: 100%;
  height: calc(100% - 45px);
  box-sizing:border-box;
  padding:0 5px;
  opacity: 0;
  top: 15px;
  position: relative;
  transition:.3s ease-in-out top;
  
  &.display{
    opacity: 1;
    transition: .6s ease-out opacity, .3s ease-in-out top;
    top: 0;
  }

  &.mobile{
    padding: 20px;
    top: 0;
    transition: .6s ease-out opacity;
    
      .mobileTopBar{
        width: 100vw;
        height: 50px;
        display: flex;
        position: relative;
        left: -20px;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid #cacaca;
        margin-bottom: 10px;
    
        img.close{
          height: 15px;
          position: absolute;
          right: 10px;
        }
      }

      .radios>label{
        width: 100%;
        .radioOption{
          padding-left: 20px;
        }
      }
  }

  .radios{
    width: 100%;
    display: flex;
    justify-content: space-between;
    // height: 40px;

    &.fullWidth{
      flex-wrap: wrap;
    }

    input[type=radio]{
      display: none;
    }

    label{
      min-width: 100px;
      // max-width: 50%;
      flex: 1 1 0;
      margin: 0 2px;
    }

    .radioOption{
      height: 45px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      box-sizing:border-box;
      padding:0 10px;
      background-color: #e9e9e9;
      cursor: pointer;

      h5{
        position: relative;
        top:2px;
        color:#074B60
      }


      .boomerCircle{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border:1px solid transparent;
        margin-right: 10px;

        img{
          height: 34px;
          width: auto;
          position: relative;
          bottom: 2px;
        }
      }
    }

    input[type=radio]:checked+.radioOption{
      background-color: #074B60;

      h5{
        color:white
      }

      .boomerCircle{
        background-color: whitesmoke;
      }
    }


    .refreshBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45%;
      position: relative;
      // right: 30px;
      // top: 2px;
      background-color: $E_blue_light;
      box-sizing:border-box;
      padding:0 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: .3s all ease-out;

      &:hover{
        background-color: $E_blue_light2;
      }
      

      img{
        height: 16px;
        margin-right: 10px;
      }
      p{
        margin:0;
        font-size: 14px;
        color: $E_blue;
        position: relative;
        top:1px;
      }

      &.fullWidth{
        margin-top: 5px;
        width: 100%;
      }

      @media (max-width:660px){
        width: 100%;
      }
    }
  }

  .loaderDiv{
    height:200px;
    width: calc(100% + 10px);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      height: 120px;
    }

  }

  .entryText{
    height: calc(100% - 60px);
    width: calc(100% + 10px);
    box-sizing:border-box;
    padding:0 15px 0 0 ;
    margin-top: 20px;
    overflow: scroll;
    opacity: 0;
    font-size: 15px;
    line-height: 27px;
    font-weight: 300 !important;
  
    &.display{
      opacity: 1;
      transition: .5s linear opacity;
      *{
      }
    }


    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #c4c4c4;
    }
  }


  @media (max-width:1024px){

    .radios{
      flex-direction: column;
      height: fit-content;

      label{
        width: 100%;
        max-width: 100%;

        &:first-of-type{
          margin-bottom: 5px;
        }
      }
    }

    .entryText{
      height: fit-content;

    }
  }
}