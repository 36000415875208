@import '../../Styles/shared.scss';

.container{
  padding: 0 !important;

  .topBar{
    height: 65px;
    // overflow: hidden;
    width: 100%;
    background-color: white;
    box-sizing:border-box;
    padding:0 30px;
    position: relative;
    border-bottom: 1px solid #cacaca;
    // transition: .3s all ease;

    &.tall{
      height: 110px;
    }
    
    
    .titleBar{
      display: flex;
      align-items: center;
      height: 65px;
      width: 100%;
      position: relative;

      .right{
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;

        // .viewRadios{

        //   input{
        //     display: none;
        //   }
        //   display: flex;
        //   align-items: center;

        //   .viewRadio{
        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        //     box-sizing:border-box;
        //     padding:0 20px 0 15px;
        //     width: 130px;
        //     height: 40px;
        //     border-radius: 5px;
        //     opacity: .5;
        //     cursor: pointer;
        //     transition: .3s all ease;
        //     background: #D2D2D2;
        //     margin: 0 4px;

        //     &:hover{
        //       opacity: 1;
        //     }

        //     p{
        //       font-size: 13px;
        //       position: relative;
        //       top:1px;
        //       font-weight: 300;
        //     }

        //     .listIcon,
        //     .gridIcon{
        //       height: 20px;
        //       width: 20px;
        //     }

        //     .listIcon{
        //       background: url('../../Assets/Icons/view-list_black.svg') no-repeat center;
        //     }
        //     .gridIcon{
        //       background: url('../../Assets/Icons/view-grid_black.svg') no-repeat center;
        //     }
        //   }

        //   input:checked+.viewRadio{
        //     background: #074B60;
        //     opacity: 1;
        //     p{
        //       color: white;
        //     }


        //     .listIcon{
        //       background: url('../../Assets/Icons/view-list_white.svg') no-repeat center;
        //     }
        //     .gridIcon{
        //       background: url('../../Assets/Icons/view-grid_white.svg') no-repeat center;
        //     }
        //   }
        // }
      }

      img.arrow{
        width: 25px;
        margin-right: 15px;
        position: relative;
        top:3px;
        transition: .1s ease-in all;
        
        &:hover{
          transform: scale(1.1);
        }
      }
      
      h3{
        font-family: "Gotham";
        max-width: calc(100% - 390px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333333;
      }
      
      .classIcon{
        max-height: 45px;
        min-height: 45px;
        max-width: 45px;
        min-width: 45px;
        border-radius: 50%;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
  
        img{
          max-height: 30px;
          min-height: 30px;
          max-width: 30px;
          min-width: 30px;
          object-fit: contain;
        }
      }
    }

    .classMates{
      display: flex;
      align-items: center;

      img,
      .moreStudents{
        height: 40px;
        width: 40px;
        object-fit: contain;
        border-radius: 50%;
        border:2px solid $E_blue;
        position: relative;
        background-color: white;
        cursor: pointer;
      }

      .moreStudents{
        line-height: 40px;
        border-radius: 30px;
        text-align: center;
        color: white;
        background-color: $E_blue;
        font-weight: 700;
        font-size: 20px;
        // left: -20px;
        cursor: pointer;
        transition: .3s all ease-out;
        
        span{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%, -50%);
          transition: .15s all ease-out;
          white-space: nowrap;

          &.oval{
            opacity: 0;
          }
        }
        &:hover,
        &.viewAll{
          width: 80px;

          span{
            &.oval{
              opacity: 1;
              font-size: 14px;
            }
            &.circle{
              opacity: 0;
            }
          }
        }
      }
      .avatars{
        display: flex;
        margin-left: 15px;
        align-items: center;
        height: 100%;

        div{
          position: relative;
          height: 40px;
          bottom: 2px;
          .tooltip{
            position: absolute;
            top: 25px;
            height: 30px;
            line-height: 30px;
            background-color: white;
            font-size: 12px;
            font-weight: 300;
            white-space: nowrap;
            left:50%;
            transform: translateX(-50%);
            padding:0px 10px;
            pointer-events: none;
            opacity: 0;
            border-radius: 5px;
            transition: .15s all ease-out;
            -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
            box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
          }

          &:hover{
            .tooltip{
              opacity: 1 !important;
              top: 45px !important;
            }
          }
        }
      }

    }
    

    .radios{
      height: 45px;
      display: flex;

      label>input[type=radio]{
        display: none;
      }

      label>input+.filterRadio{
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 10px;
        position: relative;

        &::after{
          content: "";
          width: 0%;
          height: 0px;
          background-color: #077CA0;
          position: absolute;
          bottom: 0;
          transition: .2s all ease;
          left: 50%;
          transform: translateX(-50%);
        }

        h6{
          color: #858585;
          font-size: 14px;
          font-weight: 500;

          &:hover{
            color:#59BDDC
          }
        }
      }
      label>input:checked+.filterRadio{

        &::after{
          width: 100%;
          height: 3px;
        }

        h6{
          color: #077CA0;
        }
      }
    }
  }

  .main{
    width: 100%;
    height: calc(100vh - 75px);
    position: relative;
    overflow: scroll;
    box-sizing:border-box;
    padding:10px;

    h3{
      color: white;
    }

    .sectionsModal{
      height:calc(100% - 75px);
      width: calc(100% - 85px);
      position: fixed;
      top: 75px;
      left: 85px;
      z-index: 10;
      display: none;
      
      &.show{
        display: initial;
      }
      
      @media (max-width:865px){
        left: 0;
        height:calc(100% - 175px);
        width: 100%;
      }

    }
  }

  @media (max-width:650px){

    .main{
      padding: 10px 20px;
      height: fit-content;
    }
    .topBar{
      height: 75px !important;
      .titleBar{
        h3{
          max-width: calc(100% - 170px);
        }

        .right{
          display: none;
        }

      }
      .radios{
        display: none;
      }
    }

  }

}