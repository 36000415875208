.container{
  display: none;

  @media (max-width:650px){
    display: block;
    width: 95vw;
    margin: 0 auto;
    height: 90vh;
    position: relative;
    top: 100%;
    background-color: white;
    border-radius: 15px;
    transition: .3s all ease;
    box-sizing:border-box;
    padding:30px 0 0 ;


    .slideHandle{
      display: block;
      height: 5px;
      border-radius: 10px;
      width: 50%;
      margin: 10px auto  30px auto;
      background-color: #cacaca;
    }

    &.show{
      top:70px
    }

    .radios{
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      position: relative;
    }


    .swipeCatcher{
      display: block;
      position: absolute;
      height: 100%;
      width:98%;
      z-index: 2;
      top: 2px;
      left: 1%;
    }
  }
}