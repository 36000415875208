@import '../../../Styles/shared.scss';

.container{
  box-sizing:border-box;
  padding:0 0 0 5px;
  position: relative;
  cursor: pointer;


  .numberCircle{
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6EDEF;
    margin-right: 5px;
     p{
       font-size: 12px;
       margin: 0;
     }
  }

  .menuDiv{
    display: flex;
    align-items: center;
    border:1px solid #f2f2f2;
    border-radius: 8px;
    padding: 0 1px 0 5px;

    .chevron{
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
      max-width: 30px;
      background: url('../../../Assets/Icons/arrow_square_chevron.png') no-repeat center;
      transition: .3s ease-in transform;

      &.open{
        background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
        transform: rotate(180deg);
      }
      
      &:hover{
        background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
      }
    }
  }

  .dropdownItems{
    height: fit-content;
    position: absolute;
    top:33px;
    left:-2px;
    border-radius: 5px;
    transition: 250ms ease-out all;
    overflow: hidden;
    background-color: white;
    z-index: 10;
    border:1px solid transparent;
    max-height: 0;
    pointer-events: none;
    z-index: 20;
    
    &.open{
      pointer-events: all;
      transition: .8s linear all;
      max-height: 500px;
      border:1px solid #f2f2f2;
    }
    // &.closed{
    //   max-height: 0;
    //   border:1px solid transparent;
    //   transition: 250ms ease-out all;
    // }


    label>input{display: none}

    .itemRow{
      display: flex;
      align-items: center;
      height: 35px;
      box-sizing:border-box;
      padding:0 10px;
      cursor: pointer;

      h6{
        width: calc(100% - 45px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.selected{
        background-color:#ebedee6a;
        pointer-events: none;
      }

      &:hover{
        background-color:#E6EDEF
      }

    }
  }
}