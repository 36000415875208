
.react-3d-carousel, .react-3d-carousel div{
  height: 600px !important;
  width: 100%;
  display: flex !important;
  align-items: center !important;
  

  img{
    max-height: 550px !important;
    height: auto !important;
    width: auto !important;
  }

  .slider-right,
  .slider-left{
    width: 50px !important;
    >div{
      border: none !important;
      i:before{
        font-size: 30px;
      }
    }
  }

  .slider-container{
    left: 39% !important;
  }

  .slider-right{
    left: 103% !important;
  }

  .slider-single{
    width: 790px !important;
  }

  .slider-left{
    right: 105% !important;
  }

  .slider-single-content{
    box-shadow: none !important;
  }

  .proactive .slider-single-content{
    left: 70px !important;
    // border:1px solid chartreuse !important; 
  }
  .preactive .slider-single-content{
    left: -70px !important;
    // border:1px solid red !important; 
  }

  @media (max-width:660px){
    
  .slider-single{
    width: calc(100vw - 30px) !important;
  }


  .slider-container{
    left: 35% !important;


    .slider-right{
      left: 85vw !important;
    }

    .slider-left{
      right: 85vw !important;
    }


  }
  }
}