@import '../../../../../Styles/shared.scss';

.container{
  height: 100%;
  overflow: hidden;
  position: relative;
  transition: .3s ease-in-out top;
  opacity: 0;
  top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  
  &.display{
    top: 0;
    opacity: .8;
    transition: .5s ease-out opacity, .3s ease-in-out top;
  }


  &.mobile{
    top: 0 !important;
    transition: .5s ease-out opacity !important;
    padding: 10px;
    
  }

  .heading{
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 10px;
    img{
      height: 20px;
      margin-right: 10px;
    }
  }

  .mobileTopBar{
    width: 100vw;
    height: 50px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #cacaca;
    margin-bottom: 20px;

    img.icon{
      height: 25px;
      margin-right: 10px;
    }

    img.close{
      height: 15px;
      position: absolute;
      right: 10px;
    }
  }

  .commentsDiv{
    width: 100%;
    flex: 1;
    box-sizing:border-box;
    padding:0 0 0 10px;
    position: relative;
    overflow: scroll;
    margin-bottom: 10px;

    h5.noComment{
      margin-top: 30px;
      width: 100%;
      text-align: center;
    }
    
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #c4c4c4;
    }

  }

  .commentInput{
    width: 100%;
    height: 100px;
    background-color: $main-teal;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing:border-box;
    padding:10px;

    .inner{
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing:border-box;
      padding:5px;
    }

      .inputContainer{
        width:calc(100% - 105px);
        height: 100%;
        border:1px solid #CDE1E8;
        background-color: #CDE1E8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        position: relative;
    
        textarea{
          min-height:45px;
          height: auto;
          width:calc(100% - 6px);
          background-color: white;
          border: 1px solid #077CA0;
          border-radius: 6px;
          position: relative;
          padding: 15px 100px 5px 8px;
          resize: none;
          font-weight: 300;
          font-size: 15px;
          margin:4px !important;
          font-family: 'Sofia';
          line-height: 20px;
        }

      }

    .sendBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 45px;
      border-radius: 10px;
      background-color: #83BDD0;
      cursor: pointer;
      transition: .2s all ease-out;

      p{
        color: white;
        font-weight: 500;
        margin-right: 10px;
      }
      &:hover,
      &.active{
        background-color: #077CA0;
      }
    }
  }

  @media (max-width:1024px){
    padding-bottom: 20px;
    // height: fit-content;
    
    // .commentsDiv{
    //   height: fit-content;
      
    // }
  }
}