
@import '../../../Styles/shared.scss';

.container{
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  box-sizing:border-box;
  padding:20px 30px;

  .main{
    width: calc(100% - 240px);
    position: relative;

    .cover{
      height: 200px;
      width: 200px;
    }
  }

.classes,
.pendingTasks{
  transition: .3s ease-in-out all;
  position: relative;
  bottom: 0px;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 15px;

  // &.small{
  //   width: calc(100% - 230px);
  // }

  // &.mid{
  //   width: calc(100% - 90px) !important;   
  // }


.viewAllBtn{
  height: 40px;
  line-height: 40px;
  background-color: $E_blue_light;
  border-radius: 5px;
  cursor: pointer;
  color: $E_blue_dark;
  text-align: center;
  width: fit-content;
  padding:0 40px;
  transition: .2s all ease;
  position: relative;
  left: calc(50% - 90px);
  margin-top: 15px;

  &:hover{
    background-color: $E_blue_light2;
  }
}


  .topBar{
    height: 55px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 25px;

    .arrow{
      height:27px;
      width: 27px;
      cursor: pointer;
      background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
      transition: .3s ease all;
      transform: rotate(-180deg);
      position: relative;
      bottom:2px;
      margin-left: 5px;
      
      &.collapsed{
        transform: rotate(-90deg);
        background: url('../../../Assets/Icons/arrow_square_chevron.png') no-repeat center;
        
  
        &:hover{
          background: url('../../../Assets/Icons/arrow_square_chevron_hover.png') no-repeat center;
        }
      }
    }
  }
  
      .text{
        display: flex;
        align-items: center;
        
        .numberCircle{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          background: #fb3333;
          margin-right: 8px;
          margin-left: 10px;
          color: white;
          line-height: 15px;
          font-size: 15px;
          position: relative;
    
        }
  
      }

    .inner{
      display: flex;
      max-height: 1950px;
      transition: .3s ease-in-out all;
      overflow: hidden;
      border-bottom: 1px solid transparent;
      box-sizing:border-box;
      margin-left:15px;
      width: calc(100% - 15px);
      
      &.collapsed{
        max-height: 0;
        border-bottom: 1px solid #cacaca;
      }
    }


    @media (max-width:1120px){
      width: 100% !important;
      position: relative !important;
      // left: -25px !important;



      &.mid,
      &.small{
        width: 100% !important;
        position: relative !important;
        // left: -25px !important;
      }

      .inner{    
        padding: 15px;
        width: 100% !important;

        &.collapsed{
          padding:0 15px;
        }
      }
    }
    @media (max-width:650px){
      box-sizing:border-box;
      width: 100% !important;
      overflow-x: hidden;
      padding:8px !important;
      
      &.mid,
      &.small{
        width: 100% !important;
        left: -0px !important;
      }
  
      .inner{
        max-height: initial !important;
        border-bottom: 1px solid transparent !important;
      }
  
      .topBar{
        align-items: flex-end !important;
        .arrow{
          display: none;
        }
        h5{
          font-size: 18px;
          padding-left: 10px;
        }
      }
  
      .pendingTasks{
        width: 100vw !important;
        box-sizing:border-box;
      }
    }
  }

  .pendingTasks{
    max-width:1100px;
  }
  
  @media (max-width:1120px){
    flex-direction: column;


    .main{
      width: 100%;
    }
  }

  @media (max-width:660px){
    padding:10px 5px 10px 20px
  }


}

