
  ////CALENDAR STYLES
  .fc-media-screen.fc-direction-ltr.fc-theme-standard{
    width:calc(90vw - 260px) !important;
    // width:1040px !important;
    margin-left: 10px;
  }
  
  .fc-toolbar.fc-header-toolbar{
    width:calc(90vw - 260px) !important;
  
  }
  
  //top bar (month, arrows)
  .fc-toolbar-title {
    font-size: 1.75em !important;
    font-weight: 300 !important;
  }
  .fc-toolbar.fc-header-toolbar{
    height:70px !important;
    display: flex !important;
    align-items: center !important;
    box-sizing:border-box;
    padding:0 0 0 30px;
    margin: 0 !important;
  } 
  
  //default buttons (hide, replace with custom btns)
  .fc-button-group, .fc-today-button{
    display: none !important;
  }
  
  //table header
  .fc-scrollgrid-section-header {
    position: absolute;
    width: calc(90vw - 260px) !important;
    *{border:0px !important}
  }
  .fc-col-header {
    width: calc(90vw - 260px) !important;
  }
  // table header day cell
  .fc-col-header-cell.fc-day{
    text-align: left;
    border-bottom: none;
  }
  
  // day block
    //number text
  .fc-daygrid-day-number {
    padding: 3px 7px !important;
    color: #333333 !important;
    font-size: 14px;
    width: 24px !important;
  }
    // day text
  .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 7px 10px !important;
    color: #858585 !important;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
    // day text TODAY
  .fc-day-today{
    background-color: white !important;
    .fc-daygrid-day-number {
      width: 20px;
      text-align: center;
      background-color: rgb(6, 95, 229);
      border-radius: 50%;
      color: white !important;
      padding: 0px 3px !important;
      margin: 3px 5px 0 0;
    }
  }
  
  //day block 
  .fc-daygrid-day-frame{
    height:120px !important;
  }

  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
    // color:chartreuse;
    position: relative;
    bottom:2px;

  }
  
  // event 
  .fc-daygrid-event{
    cursor: pointer;
    height: 25px !important;
    border-color: white !important;
    color: white !important;
    margin-top: 5px !important;
    
    *{ height: 24px !important; }
  }
    //event title
    .fc-event-title-container{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 80px);
      display: flex;
      align-items: center;
  
      span{
        color:white !important;
        font-size: 14px !important;
        font-weight:300 !important;
        margin-left: 30px !important;
        // position: relative;
        // top:6px;
        height: fit-content !important;
      }
    }
    .fc-event-title{
      line-height: 24px;
      font-size: 14px;
      padding-left: 40px !important;
    }
    .fc-daygrid-event-dot+.fc-event-title{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 80px);
  
    }
    // end date marker (unwanted)
    .fc-daygrid-event-dot{
      display: none;
    }
    .fc-daygrid-day-events{
      height: 75%;
    }
    // 'more' marker
    .fc-daygrid-more-link{
      position: absolute !important;
      bottom: 0 !important;
      right: 3px !important;
      color: #858585 !important;
    }
  
    //popover
    .fc-more-popover{
      width: 400px;
    }
    .fc-popover-header{
      background-color: white !important;
      .fc-popover-title{
        font-weight: 300;
        color: #333333;
      }
    }
  
  