@import '../../../Styles/shared.scss';

.container{
  width: 100%;
  background-color: #d2d2d2;
  position: relative;
  overflow: hidden;

  .inner{
    position: absolute;
    height: 100%;
    background: rgb(255,116,38);
    transition: .5s linear width;
    background: linear-gradient(128deg, rgba(255,116,38,1) 0%, rgba(255,168,38,1) 100%);;
  }

}