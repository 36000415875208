.profileSidebar{
  width: 65px;
  height: 100%;
  background-color: #073746;
  position: absolute;
  left: 0;
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);
  box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing:border-box;
  padding:20px 0;

  input[type=radio]{
    display: none;
  }

  label>input+.viewRadio{
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    opacity: .5;
    cursor: pointer;
    border:1px solid transparent;
    transition: .2s all ease;
    
    &:hover{
      border:1px solid #395D69;
      background-color: #395d697c; 
    }
  }

  label>input:checked+.viewRadio{
    background-color: #395D69;
    opacity: 1;
  }


  @media (max-width:660px){
    display: none;
  }

}