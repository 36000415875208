@import '../../../Styles/shared.scss';

.listView{
  width: calc(100% - 60px);
  position: absolute;
  opacity: 1;
  transition: .3s ease-in all, .4s cubic-bezier(0.2,-0.1, 0.5, 3) bottom;
  
  &.hide{
    opacity: 0;
  }
    
  &.centered{
    height: calc(100vh - 100px);
    width: calc(100vw - 200px);
    justify-content: center;
    align-items: center;
    box-sizing:border-box;
    padding:0 0 50px 0;
    display: flex;
  }


  .titleRes{
    display: none;
  }


  .noActivities {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    min-height: 480px;
    max-width: 750px;
    width: 70vw;
    background-color: white;
    border-radius: 10px;
    position: relative;

    img {
      width: 180px;
      margin-bottom: 20px;
    }

    h3 {
      width: 350px;
      text-align: center;
      margin: 15px 0;
      font-size: 25px;
      line-height: 35px;
      color: $main-purple--dark;
    }


    .goBackBtn {
      height: 50px;
      width: 280px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      font-size: 20px;
      color: white;
      background-color: $E_blue;
      cursor: pointer;
      transition: .15s all ease-out;
      
  
      &:hover {
        background-color: $E_blue_dark;
      }
    }

  }

  .radiosSlider{
    position: relative;

    .inner{
      display: flex;
      align-items: center;
      overflow: scroll;
      padding-bottom: 5px;


    .arrowRight{
      transform:  translateY(-50%) rotate(180deg);
      left: initial;
      right: -5px ;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #c4c4c4;
    }

    }

    .responsiveDots{
      display: none;
    }

    .responsiveDots,
    .arrowLeft,
    .arrowRight{
      position: absolute;
      height: 32px;
      width: 32px;
      border-radius: 8px;
      top: 50%;
      transform: translateY(-50%);
      opacity: .7;
      border:1px solid #cacaca;
      background: url('../../../Assets/Icons/arrow_square2.png') center no-repeat;
      left:-5px;
      z-index: 10;
      cursor: pointer;
      transition: .15s all ease-in;

      &:hover{
        opacity: 1;
      }

    }

  }
  .list{
    margin-top: 15px;
    height: calc(100% - 70px);
    width: 100%;
  }

  @media (max-width:650px){
    width: 100vw;
    left: 0px;
    overflow: hidden;

    .radiosSlider{
      .inner{
        width: 104vw;
      }
      .responsiveDots{
        display: block !important;
        background: url('../../../Assets/Icons/three_dots_square.png') center no-repeat;
        right: 4px;
        top:35px;
        left: initial;
        opacity: 1;
      }
    }


    h4.titleRes{
      display: block;
      color: #333333;
      position: relative;
      top: 10px;
      left: 20px;
      margin-bottom: 20px;
    }


    .arrowLeft,
    .arrowRight{
      display: none;
    }
  }
}