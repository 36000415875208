@import './variables.scss';
@import './fonts.scss';
@import './mixins.scss';
@import './animations.scss';
@import './modalAdditionlClasses.scss';
@import './quillEditorStyles.scss';
@import './carouselStyles.scss';
@import './autoFeedbackElementStyles.scss';
@import './calendarOverrirdeStyles.scss';
@import './masonry.scss';



:root{
  --viewportHeight : 100vh;
  --viewportMinus : 100vh;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: 'Gotham', sans-serif;
}

input,
select,
textarea,
button{
  -webkit-appearance: none; 
  -moz-appearance: none; 
  border:none;
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
a,
a:visited{
  color: inherit;
}

body {
  font-family: 'Gotham', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Gotham', sans-serif;
}

h1{
  font-size: 35px;
}

h2{
  font-size: 25px;
}

h3{
  font-size: 20px;
  line-height: 30px;
}

h4{
  font-size: 18px;
  line-height: 30px;
}

h5{
  font-size: 15px;
  line-height: 30px;
}

h6{
  font-size: 14px;
  line-height: 30px;
}

h5.long{
  font-size: 20px;
  line-height: 25px;;
  @media (max-width:450px){
    max-width: 80vw !important;
    min-height: 75px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
h5.medium{
  font-size: 25px;
  @media (max-width:450px){
    max-width: 80vw !important;
    // min-height: 60px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
h5.short{
  font-size: 30px;
  @media (max-width:450px){
    max-width: 80vw !important;
  }
}

ul {
  list-style: none;
}

.show{
  display: initial !important;
}

.hideRes{
  @media (max-width:865px){
    display: none !important;
  }
}

.synodWrapper{
  min-height: 600px;
  height: var(--viewportHeight);
  width: 100vw;
}

.appWrapper{
  min-height: 600px;
  height: var(--viewportHeight);
  overflow: auto;
  display: flex;

  @media (max-width:865px){
    min-height: 100vh;
    flex-direction: column-reverse;
  }

  @media (max-width:950px){
  }
  @media (max-width:650px){
    min-height: -webkit-fill-available;
  }
}

.bodyWrapper{
  position: relative;
  z-index: 5;
  height: var(--viewportHeight);
  width: 100%;
  min-height: 600px;
  overflow: scroll;

  >section{
    width: calc(100vw - 83px);
    // width: calc(100vw - 85px);
    min-height: 600px;
    height: 100% !important;
    padding:20px 30px;
    overflow: scroll;
    
    @media (max-width:865px){
      width: 100vw;
      height: 100%;
      overflow: auto;
      min-height: initial;
    }
    @media (max-width:650px){
      padding: 0;
      width: 100vw;
      overflow-x: hidden;
    }
  }
  
  
  @media (max-width:865px){
    min-height: initial;
    width: 100vw;
    height: calc(100vh - 90px);
    overflow: auto;

    &.fullHeight{
      height: var(--viewportHeight) !important;
    }
  }

  @media (max-width:650px){
    height: 100vh;
    // overflow: hidden;
  }
}
