@import '../../Styles/shared.scss';

.container{
  display: none;
  position: relative;

  .sections{
    width: 100%;
    height: calc(var(--viewportHeight) - 100px);
    overflow: auto;
  }

  .spacer{
    height: 100px;
    width: 100%;
  }

  .break{
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
    margin:40px 0 60px 0;
  }

  .entryPreview{
    width: calc(100% - 50px);
    margin: 0 auto;
    -webkit-box-shadow: 0px 6px 20px -2px rgba(0, 99, 192, 0.3);
    -moz-box-shadow:    0px 6px 20px -2px rgba(0, 99, 192, 0.3);
    box-shadow:         0px 6px 20px -2px rgba(0, 99, 192, 0.3);
    height: fit-content;
    min-height: calc(var(--viewportHeight) - 400px);
    border-radius: 15px;
    box-sizing:border-box;
    padding:20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .topRow{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .textDiv{
      min-height: calc(var(--viewportHeight) - 550px);
      width: 100%;
    }

    .editBtn{
      font-weight: 500;
      padding: 10px 25px;
      border-radius: 10px;
      background-color: $E_blue;
      color: white;
    }

    .wordCount{
      h5{
        font-size: 13px;
        color: #858585;
        font-weight: 500;
      }
    }

    .grade{
      display: flex;
      align-items: center;
      position: relative;
      bottom: 2px;
      h5{
        font-size: 17px;
        margin: 0;
      }

      .gradeDiv{
        margin-left: 10px;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid transparent;
      }

      &.hidden{
        opacity: 0;
      }

      &.one{
        .gradeDiv{
          border-color: #d00000;
        }
         h6,h5{
           color:#d00000;
         }
       }
       &.two{
         .gradeDiv{
           border-color: #FF8258;
          }
         h6,h5{
           color:#FF8258;
         }
       }
       &.three{
         .gradeDiv{
           border-color: #97D318;
          }
         h6,h5{
           color:#97D318;
         }
       }
       &.four{
         .gradeDiv{
           border-color: #75972D;
          }
         h6,h5{
           color:#75972D;
         }
       }
    }
  }

  .editorDiv{
    position: fixed;
    top: 100vh;
    opacity: 0;
    pointer-events: none;
    left: 0;
    z-index: 50;
    background-color: white;
    width: 100vw;
    height: 100vh;
    transition: .5s all ease;

    &.show{
      opacity: 1;
      top: 0;
      pointer-events: all;
    }
  }

  textarea.storyStart{
    width: 100vw;
    box-sizing:border-box;
    padding:0 20px;
    height: calc(100% - 15px);
    resize: none;
    margin: 10px auto;
    font-family: Sofia;
    font-weight: 300;
    font-size: 16px;
  }
  
  @media (max-width:650px){
    display: initial;
    height: 100%;
    width: 100%;
    position: relative;

    .content{
      transition: .6s ease-in-out;
      height: 0;
      width: 100vw;
      position: absolute;
      z-index: 20;
      top: 0;
      overflow: scroll;
      
      &.open{
        box-sizing:border-box;
        border-bottom: 3px solid #cacaca;
        height: 100vh;
        background-color: white;
      }

    }
  }
}