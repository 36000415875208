
.modalOverlay{
  position: fixed;
  z-index: 1050;
  width:calc(100vw - 80px);
  top:0;
  left:80px;
  height: 100%;
  min-height: 600px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;

  &.synod{
    width: 100%;
    left: 0;
    top: 60px;
    height: var(--viewportMinus);
  }

  &.hidden{
    visibility: hidden;
  }

  .inner{
    background-color: white;
    position: relative;
    border-radius: 15px;

  }

  @media (max-width:865px){
    width: 100vw;
    // height: calc(100vh - 90px);
    left: 0;
    min-height: initial;
  }

}