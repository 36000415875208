@import '../../../Styles/shared.scss';

.container{
  height: 100%;
  width: 135px;
  min-width: 135px;
  max-width: 135px;
  border-left: 1px solid #cacaca;
  // transition: 0.45s ease-in all;
  transition: .8s cubic-bezier(0.67, -0.19, 0.57, 1.3) all;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  box-sizing:border-box;
  padding:5px 0;
  
  &.open{
    width: 500px;
    min-width: 500px;
    max-width: 500px;
    transition: 0.9s cubic-bezier(0.4, -0.4, 0.4, 1.4) all;
  }

  .topBar{
    height: 50px;
    width: 100%;
    padding: 5px 15px 10px 5px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 0px;
    position: relative;

    h4.synodHeading{
      font-family: "DM Sans";
      background: url('../../../Assets/Icons/instructions_blue_dark.svg')no-repeat left center;
      background-size: 20px;
      padding-left: 30px;
      margin-left: 10px;
      padding-top: 2px;
      color: $E_blue_dark;
      font-size: 20px;
    }

   

    .controlBtns{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 75px;

      &.synod{
        justify-content: flex-end;
      }
  
  
      .expand,
      .closeArrow{
        min-height: 35px;
        max-height: 35px;
        min-width: 35px;
        max-width: 35px;
        border-radius: 10px;
        background: url('../../../Assets/Icons/expand.svg') no-repeat center;
        cursor: pointer;
        opacity: 0;
        transition: .6s linear all;
        
        &:hover{
          background: url('../../../Assets/Icons/expand_hover.svg') no-repeat center;
        }
  
        &.display{
          opacity: 1;
        }
      }
  
      .closeArrow{
        background: url('../../../Assets/Icons/arrow_square3.svg') no-repeat center;
        
        &:hover{
          background: url('../../../Assets/Icons/arrow_square_hover3.svg') no-repeat center;
        }
      }
    }
  }

  
  .openContent{
    width: 0px;
    height: 100%;
    transition: .8s cubic-bezier(0.67, -0.19, 0.57, 1.3) all, .8s linear opacity;
    overflow: hidden;
    opacity: 0;
    overflow: hidden;
    
    
    &.display{
      width: 100%;
      opacity: 1;
      transition: 0.9s cubic-bezier(0.4, -0.4, 0.4, 1.4) all, .9s linear opacity;
    }
    
    .openMain{
      width: 100%;
      margin: 0 auto;
      box-sizing:border-box;
      padding:0 15px;
      height: calc(100% - 50px);
      overflow: scroll;
      
      
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #c4c4c4;
      }
      
    }
  }

  @media (max-width:1110px){

    // overflow: scroll !important;
    
    &.open{
      width:350px;
      min-width:350px;
      max-width:350px;
    }

    .openContent{
      overflow: scroll !important;

      &.display{
        width: 350px;
      }

      .openMain{
        width: 335px;
        overflow: initial;
      }


    }
  }
}