@import '../../../../../Styles/shared.scss';

.container{

  width: 100%;
  box-sizing:border-box;
  box-sizing:border-box;
  padding:0 5px;
  transition: .6s ease-out opacity, .3s ease-in-out top;
  position: relative;
  opacity: 0;
  top: 15px;
  
  &.display{
    opacity: 1;
    top: 0;
  }

  &.isModal{

    .bottom{
      display: flex;
      flex-wrap: wrap;
      box-sizing:border-box;
      padding:0 15px;

      .wordBankWrapper,
      .imagesOuterWrapper,
      .filesOuterWrapper{
        width: 33%;
        flex-direction: column;
        .sectionTitle{
          margin:0 0 10px 0;
        }
      }
    }

  
  }
  
  &.mobile{
    opacity: 1;
    top: 0;
    // height: calc(var(--viewportHeight) - 90px);
    height: fit-content;
    transition: .6s ease-out all;
    padding: 20px 20px;


    .mobileTopBar{
      width: 100vw;
      height: 50px;
      display: flex;
      position: relative;
      left: -20px;
      justify-content: center;
      align-items: center;
      border-bottom: 2px solid #cacaca;
      margin-bottom: 10px;

      img.icon{
        height: 25px;
        margin-right: 10px;
      }

      img.close{
        height: 15px;
        position: absolute;
        right: 10px;
      }
    }
  }

  
  .instructionsText{
    width: 100%;
    height: fit-content;

    *{
      font-size: 16px !important;
      // font-weight: 300 !important;
      margin-bottom: 10px !important;
    }

      img,
      iframe{
        display: block !important;
        margin: 25px 10px !important;
      }

      img{
        width: 400px !important;
      }
      iframe{
        width: 400px !important;
        height: 225px !important;
      }
    
  }

  .sectionTitle{
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom:10px;

    &.synod{h5{
      font-weight: 500;
    }

    }

    img{
      margin-right: 7px;
      height: 17px;

      &.file{
        height: 25px;
      }
    }
  }

  .wordBankWrapper{
    width: 100%;
    flex-direction: column;

    .titleRow{
      display: flex;
      align-items: center;

      .icon{
        width: 25px;
        height: 25px;
        background: url('../../../../../Assets/Icons/wordbank_blue.svg') no-repeat center;
        background-size: contain;
        margin-right: 10px;
      }
      .tip{
        position: relative;
        height: 20px;
        width: 20px;
        background: url('../../../../../Assets/Icons/question-light.svg')no-repeat center;
        margin-left: 5px;
        cursor: pointer;
        background-size: 18px;
        
        .tooltip{
          width: 200px;
          transition: .2s all ease-out;
          background-color: white;
          border-radius: 10px;
          padding:10px;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          cursor: pointer;
          -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
          -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
          box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.39);
          font-weight: 300;
          pointer-events: none;
          opacity: 0;
        }

        &:hover{
          background: url('../../../../../Assets/Icons/question.svg')no-repeat center;
          background-size: 18px;

          .tooltip{
            top: 22px;
            opacity: 1;

          }
        }
      }
    }

    .words{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      .wordbankItem{
        height: 30px;
        padding:0 15px;
        border-radius: 8px;
        line-height: 30px;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: $E_blue_light;
        color: $E_blue;
        font-weight: 300;

        &.used{
          padding-right: 30px;
          color: white;
          background: url('../../../../../Assets/Icons/tick_grey.svg') center right 10px no-repeat;
          background-color: $main_green;
        }
      }
    }


  }

  .imagesWrapper,
  .filesWrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;

    .imgDiv{
      height: 130px;
      width:130px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      margin-bottom: 8px;
      overflow: hidden;

      img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        opacity: .5;
        transition: .2s ease-out all;
        cursor: pointer;
      }

      &:hover{
        img{
          opacity: 1;
          transform: scale(1.05);
        }
      }
    }

    .fileDiv{
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      box-sizing:border-box;
      padding:0 3px 0 15px;
      width: 260px;
      height: 40px;
      border-radius: 8px;
      background-color: #e9e9e9;
      margin-bottom: 6px;
      cursor: pointer;

      &:hover{

        .download{
          background: url('../../../../../Assets/Icons/download_arrow_black.svg') no-repeat center,#D2D2D2;
          background-size: 11px;
        }
      }

      p{
        color: #065FE5;
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        width: calc(100% - 40px);
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .download{
        height: 20px;
        width: 20px;
        border-radius: 8px;
        background: url('../../../../../Assets/Icons/download_arrow_grey.svg') no-repeat center;
        background-size: 11px;
        padding: 12px;
      }
    }
  }

  @media (max-width:1024px){
    .imagesWrapper{
      justify-content: center;

      .imgDiv{
        flex-grow: 1;
      }
    }

    .filesWrapper{
      padding-bottom: 30px;
    }
  }

  @media (max-width:650px){
    overflow-y: auto;
    overflow-x: hidden;
    
    .filesWrapper{
      padding-bottom: 0px;
    }
    .sectionTitle,
    .imagesWrapper,
    .filesWrapper{
      justify-content: flex-start;
      .imgDiv{
        // width: 200px;
      }
    }

    .filesWrapper{
      flex-direction: column;
      // align-items: center;
    }
  }


}