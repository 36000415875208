.container{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;


  .itemRadios{
    height: 100%;
    box-sizing:border-box;
    width: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing:border-box;
    padding:10px 15px;
    overflow-x: hidden;
    overflow-y: auto;

    // input[type=radio]{
    //   display: none;
    // }

    .itemRadio{
      top: 0px;
      border-radius: 10px;
      width: 120px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      background-color: #DCE6E9;
      cursor: pointer;
      position: relative;
      transform-style: preserve-3d;
      transition: .3s all ease, 1.4s ease background-color;




      &::after{
        top: -0px;
        content: "";
        position: absolute;
        border-radius: 10px;
        height: 70px;
        width: 80px;
        background: rgb(12,167,216);
        background: linear-gradient(196deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 100%);
        transform: translateZ(-1px);
        transition: .3s all ease;
        -webkit-box-shadow: 0px 5px 10px -2px rgba(6, 0, 0, 0);
        -moz-box-shadow:    0px 5px 10px -2px rgba(6, 0, 0, 0);
        box-shadow:         0px 5px 10px -2px rgba(6, 0, 0, 0);
  
      }

      img{
        transition: .3s all ease;
        opacity: .4;
      }

      &:hover{
        background-color: white;
        -webkit-box-shadow: 0px 5px 10px -2px rgba(6, 0, 0, .3);
        -moz-box-shadow:    0px 5px 10px -2px rgba(6, 0, 0, .3);
        box-shadow:         0px 5px 10px -2px rgba(6, 0, 0, .3);
        img{
          opacity: 1;
        }
      }
    }
    
    input:checked+.itemRadio{
      top: 3px;
      height: 85px;
      width: 110px;
      margin-bottom: 20px;
      opacity: 1;
      pointer-events: none;
      background-color: white;

      img{
        opacity: 1;
      }

      &::after{
        height: 100px;
        width: 120px;
        top: -3px;
      }

      
    }
  }

  .main{
    width: calc(100% - 125px);
    box-sizing:border-box;
    padding:10px;


  .sectionRadios{
    height: 65px;
    width: 100%;
    box-sizing:border-box;
    padding:0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    label{
      height: 45px;
      width: 50%;
      
      .sectionRadio{
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e9e9e9;
        color: #4E4E4E;
        font-weight: 700;
        font-size: 18px;
        position: relative;
        overflow: hidden;
        transition: .3s all ease;
        opacity: .7;

        &:hover{
          background-color: white;
          -webkit-box-shadow: 0px 1px 7px 6px rgba(0,0,0,0.05); 
          box-shadow: 0px 1px 7px 6px rgba(0,0,0,0.05);
          opacity: 1;
        }


        &:after{
          content:"";
          background: #41C7F1;
          border-radius: 15px;
          bottom: -2.5px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          transition: .2s all ease-in-out;
          height: 0;
          width:0;
        }


        .radioIcon{
          height: 20px;
          margin-right: 15px;
          position: relative;
          bottom: 2px;
        }

        @media (max-width:1200px){
          img{
            display: none;
          }
        }

        @media (max-width:1000px){
          font-size: 15px;
        }
      }

      input:checked+.sectionRadio{
        background-color: #2D5A68;
        color: white;
        opacity: 1;

        &:after{
          height: 7px;
          width: 80%;
        }
      }

      &:first-of-type{
        .sectionRadio{
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }

      &:last-of-type{
        .sectionRadio{
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

    }



  }

    .itemsDiv{
      height: 90%;
      overflow: scroll;
      border-radius: 15px;
      -webkit-box-shadow: 0px 3px 17px 1px rgba(0,0,0,0.51); 
      box-shadow: 0px 3px 17px 1px rgba(0,0,0,0.51);
    }
  }


  .toggle{
    position: absolute;
    top: 10px;
    right: 110px;
    background-color: #074B60;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: .3s all ease;
    z-index: 10;
  
    &:hover{
      border-color: #074B60;
      background-color: white;
      color: #074B60;
  
    }
  }

  @media (max-width:660px){
    flex-direction: column;
    overflow-y: auto;

    .itemRadios,
    .main{
      width: 100%;
    }

    .main{
      height: calc(100% - 100px);
      padding: 0;

      .itemsDiv{
        box-shadow: none;
        height: fit-content;
      }

      .sectionRadios{
        display: none;
      }
    }

    .itemRadios{
      flex-direction: row;
      height: 100px;
      padding: 5px;
      justify-content: space-between;
      overflow-x: auto;

      label{
        width: 85px;
        // border:1px solid red;
      }

      .itemRadio{
        width:60px;
        height:60px;
        margin-right: 5px !important;
        top: 0px;

        img{
          width: 40px;
          height: 40px;
        }
        
        &::after{
          top: -2px;
          width: 65px;
          height:65px;
          opacity: 0;
        }
      }
      input:checked+.itemRadio{
        top: -3px;
        height: 60px;
        width: 60px;
        margin-bottom: 0px;
  
        &::after{
          height: 65px;
          width: 65px;
          top: -1px;
          opacity: 1;
        }
  
        
      }
    }
  }
}