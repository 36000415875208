@import '../../../Styles/shared.scss';

.container{
  position: absolute;
  height: 200px;
  width: 420px;
  border-radius: 15px;
  background-color: white;
  box-sizing:border-box;
  padding:25px;
  top: -40px;
  left: -175px;
  z-index: 20;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.1);
  text-align: left;
  cursor: default;
  pointer-events: none;
  transition: .15s all ease-out;
  opacity: 0;

  &.centered{
    top: initial;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  //class for displaying this component (.showModal) is in [PARENT COMPONENT].module.scss, at the bottom
  //it had to be there as the toggle function is contained in the dash component, so uses those styles

  .close{
    position: absolute;
    height: 20px;
    width: 20px;
    top:8px;
    right:8px;
    cursor: pointer;
    background: url('../../../Assets/Icons/cross--grey.svg') no-repeat center;
    background-size: 12px;
  }
  p.grey{
    font-size: 13px;
    color: #858585;
    font-weight: 500;
    margin:5px ;
  }

  input[type=text]{
    height: 40px;
    width: 100%;
    box-sizing:border-box;
    padding:0 10px;
    border-radius: 5px;
    border:1px solid #cacaca;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;

    &::placeholder{
      color:#bababa;
      font-weight: 300;
    }
  }

  p.error{
    color: red;
    position: absolute;
    bottom: 83px;
    left: 30px;
    font-size: 12px;
    font-weight: 300;
  }

  .loaderOverlay{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.512);
    border-radius: 10px;
    img{
      height: 110px;
    }
  }
  
  .createBtn{
    height: 45px;
    width: 100%;
    border-radius: 5px;
    background-color: $E_blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s all ease-out;

    &:hover{
      background-color: $E_blue_dark;
    }

    &.disabled{
      opacity: .4;
      pointer-events: none;
    }

    p{
      color: white;
      font-size: 15px;
      font-weight: 500;
    }
  }


  @media (max-width:650px){
    width: 90vw;
    left:0;
  }
}